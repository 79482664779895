import React, { useState } from 'react';
import { Tag, Tooltip, Dialog } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { useCreateWorkableInteractionMutation, useShareWorkableMutation } from 'api/workableInteractionsApi';
import { LabelsDescriptionListField } from 'app/organisms/LabelsDescriptionListField/LabelsDescriptionListField';
import { ShareViaEmailForm } from 'app/organisms/ShareViaEmailForm/ShareViaEmailForm';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { ContactIndex, USASpendingAwardIndex } from 'types/__generated__/GovlyApi';
import { DescriptionListLabel } from 'app/molecules/DescriptionList/DescriptionList';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { WorkspaceFollowers } from 'app/organisms/WorkspaceFollowers/WorkspaceFollowers';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { Loading } from 'app/atoms/Loading/Loading';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';

export type DetailsActionsProps = {
  resource: ContactIndex | USASpendingAwardIndex;
};

export const WorkableDetailsActions = ({ resource }: DetailsActionsProps) => {
  const { trackEvent } = useEventTracking();
  const [isSharing, setIsSharing] = useState(false);
  const [follow, { isLoading: isFollowing }] = useCreateWorkableInteractionMutation();
  const [share, { isLoading: isSendingShare }] = useShareWorkableMutation();
  const [searchParams] = useSearchParams();
  const showDrawerButtons = !!searchParams.get(DRAWER_PARAMS.type);

  if (!resource.id) return <Loading type="condensed-card" />;

  const { interactableType, displayableType, shareFormTitle } = match(resource)
    .with({ __typename: 'ContactIndex' }, ({ name, email }) => ({
      interactableType: 'Contact' as const,
      displayableType: 'Contact',
      shareFormTitle: `Share Contact: ${name ? name : email}`
    }))
    .with({ __typename: 'USASpendingAwardIndex' }, ({ title }) => ({
      interactableType: 'USASpendingAward' as const,
      displayableType: 'Award',
      shareFormTitle: `Share Award: ${title}`
    }))
    .exhaustive();

  const { workableInteraction: interaction } = resource;
  const lowercaseType = displayableType.toLowerCase();

  let followTagText = 'Follow';
  if (interaction?.followedAt && isFollowing) {
    followTagText = 'Unfollowing...';
  } else if (interaction?.followedAt) {
    followTagText = 'Following';
  } else if (isFollowing) {
    followTagText = 'Following...';
  }

  const sharedTo = interaction?.sharedTo.length ? interaction?.sharedTo : [];
  return (
    <Card>
      <CardBody className="sm:py-3 sm:px-6">
        <dl className="grid grid-cols-2 gap-y-4">
          <div className="col-span-2 flex flex-col space-y-2">
            <DescriptionListLabel>Followers</DescriptionListLabel>
            <div className="space-y-2">
              <WorkspaceFollowers
                formTitle={`Add Followers to ${displayableType}`}
                workspace={resource.workspace}
                type={interactableType}
                resource={resource}
              />
              <Tag
                icon="thumbs-up"
                minimal
                round
                interactive
                intent={interaction?.followedAt ? 'success' : 'none'}
                onClick={async () => {
                  const followed = !interaction?.followedAt;
                  await follow({ interactableType, interactableId: resource.id, followed });
                  trackEvent({
                    object: lowercaseType as Lowercase<string>,
                    action: followed ? 'followed' : 'unfollowed',
                    properties: {
                      resourceId: resource.id
                    }
                  });
                }}
              >
                {followTagText}
              </Tag>
            </div>
          </div>
          <LabelsDescriptionListField
            taggableId={resource.id}
            taggableType={interactableType}
            labels={resource.labels}
          />
          <DescriptionListField
            label="Actions"
            value={
              <div className="flex flex-wrap gap-2">
                <Tooltip
                  content={sharedTo.length ? `Previously shared to: ${sharedTo.join(', ')}` : ''}
                  disabled={!sharedTo.length}
                >
                  <Tag
                    intent={sharedTo.length ? 'primary' : 'none'}
                    interactive
                    round
                    minimal
                    icon="envelope"
                    onClick={() => setIsSharing(true)}
                  >{`Share via Email`}</Tag>
                </Tooltip>
                {showDrawerButtons && (
                  <Tooltip content={`Click to view ${lowercaseType} in a new tab`}>
                    <LinkTag to={`/${lowercaseType}s/${resource.id}`} target="_blank">
                      <Tag interactive round minimal icon="share">
                        Open in new tab
                      </Tag>
                    </LinkTag>
                  </Tooltip>
                )}
              </div>
            }
            cols={2}
          />
        </dl>
        <Dialog isOpen={isSharing} className="min-w-[50%] p-0">
          <ShareViaEmailForm
            title={shareFormTitle}
            onCancel={() => setIsSharing(false)}
            initialValues={{
              subject: shareFormTitle,
              id: resource.id
            }}
            isSubmitting={isSendingShare}
            onSubmit={values => share({ ...values, type: interactableType }).unwrap()}
          />
        </Dialog>
      </CardBody>
    </Card>
  );
};
