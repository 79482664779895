import React from 'react';
import type { DrawerNavItem, NavItem } from 'app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetOppAttachmentsQuery } from 'api/oppsApi';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';

export type OppAttachmentsTabProps = {
  node: NavItem | DrawerNavItem;
  index: number;
};

export const OppAttachmentsTagContent = (_: OppAttachmentsTabProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = params.id ?? searchParams.get(DRAWER_PARAMS.id) ?? '';
  const query = useGetOppAttachmentsQuery({ id }, { skip: !id });
  const count = query.data?.results.length ?? 0;
  return count > 0 ? <span>{count}</span> : null;
};
