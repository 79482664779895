import React from 'react';
import { Tag, CompoundTag } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';
import truncate from 'lodash-es/truncate';

import { Card, CardSection } from 'app/atoms/Card/Card';
import { USASpendingAwardSearch } from 'types/__generated__/GovlyApi';
import { useGetAwardsQuery, useAwardSearchMutation, AWARD_SEARCH_CACHE_KEY } from 'api/awardsApi';
import { formatAddress } from 'app/lib/address';
import { asCurrencyCompact } from 'app/lib/numbers';
import {
  AwardSearchResultHighlight,
  Highlight
} from 'app/organisms/AwardSearchResultsCards/AwardSearchResultHighlight';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { SearchResultInteractions } from 'app/organisms/SearchResultInteractions/SearchResultInteractions';
import { LabelList } from 'app/organisms/LabelList/LabelList';
import { presence } from 'app/lib/arrays';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { USASpendingAwardIndex } from 'types/__generated__/GovlyApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';

export const AwardSearchResultCard = ({ award }: { award: USASpendingAwardSearch }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [_search, { resultIds }] = useAwardSearchMutation({
    fixedCacheKey: AWARD_SEARCH_CACHE_KEY,
    selectFromResult: ({ data: { results = [] } = {} }) => ({
      resultIds: results.map(({ id: awardId }) => awardId)
    })
  });

  const { awardWithInteractions = {} as USASpendingAwardIndex } = useGetAwardsQuery(
    { ids: resultIds },
    {
      skip: !resultIds.length,
      selectFromResult: ({ data = [] }) => ({
        awardWithInteractions: data.find(({ id: awardId }) => awardId === award.id)
      })
    }
  );
  const { workspace } = awardWithInteractions;
  const displayableFollows = workspace?.follows?.filter(({ state }) => state !== 'unfollowed');

  const openAward: React.MouseEventHandler<HTMLElement> = event => {
    openDrawerOrNewTab(event, `/awards/${award.id}`, () => {
      searchParams.set(DRAWER_PARAMS.type, 'award');
      searchParams.set(DRAWER_PARAMS.id, award.id);
      setSearchParams(searchParams);
    });
  };

  const pop = formatAddress({
    city: award.primaryPlaceOfPerformanceCityName,
    state: award.primaryPlaceOfPerformanceStateName,
    zip: award.primaryPlaceOfPerformanceZip,
    country: award.primaryPlaceOfPerformanceCountryName
  });

  return (
    <Card>
      <CardSection className="flex flex-col space-y-4">
        <div className="flex flex-wrap items-center justify-between gap-2">
          <NewTabTooltip title="View Award">
            <CompoundTag
              interactive
              onClick={openAward}
              leftContent={award.awardType || award.idvType || award.awardOrIdvFlag}
            >
              {award.awardIdPiid}
            </CompoundTag>
          </NewTabTooltip>
          <SearchResultInteractions resource={awardWithInteractions} />
        </div>

        <LinkTag tag="a" onClick={openAward} className="font-medium no-underline" data-test="award-search-result">
          <NewTabTooltip title="View Opportunity">
            <h3 className="line-clamp-2">{truncate(award.primeAwardBaseTransactionDescription, { length: 500 })}</h3>
          </NewTabTooltip>
        </LinkTag>

        <AwardSearchResultHighlight highlights={award.highlight as Highlight} />

        <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
          {(award.programAcronym || award.majorProgram) && (
            <CompoundTag minimal leftContent="Program">
              {award.programAcronym || award.majorProgram}
            </CompoundTag>
          )}
          {award.parentAwardIdPiid && (
            <CompoundTag minimal leftContent="Parent Award PIID">
              {award.parentAwardIdPiid}
            </CompoundTag>
          )}

          <CompoundTag minimal leftContent="Start Date" icon="inbox-update">
            {award.periodOfPerformanceStartDate}
          </CompoundTag>
          <CompoundTag minimal intent="success" leftContent="End Date" icon="time">
            {award.periodOfPerformanceCurrentEndDate}
          </CompoundTag>

          <CompoundTag minimal leftContent="Obligated Amount" icon="dollar">
            {asCurrencyCompact(award.totalDollarsObligated ? parseFloat(award.totalDollarsObligated) : 0)}
          </CompoundTag>
          <CompoundTag minimal intent="success" leftContent="Potential Amount" icon="dollar">
            {asCurrencyCompact(award.potentialTotalValueOfAward ? parseFloat(award.potentialTotalValueOfAward) : 0)}
          </CompoundTag>

          <CompoundTag minimal leftContent="Recipent">
            {award.recipientName}
          </CompoundTag>
          <CompoundTag minimal leftContent="Awarding Agency">
            {award.awardingAgencyName}
          </CompoundTag>
          <CompoundTag minimal leftContent="Awarding Sub-Agency">
            {award.awardingSubAgencyName}
          </CompoundTag>
          <CompoundTag minimal leftContent="Awarding Office">
            {award.awardingOfficeName}
          </CompoundTag>

          {presence(pop) && (
            <CompoundTag minimal intent="primary" leftContent={null} icon="map-marker">
              {pop.join(', ')}
            </CompoundTag>
          )}

          {award.typeOfSetAside && (
            <Tag minimal intent="warning" icon="pie-chart">
              {award.typeOfSetAside}
            </Tag>
          )}
        </div>

        {presence(awardWithInteractions?.labels) && (
          <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
            <LabelList labels={awardWithInteractions?.labels ?? []} />
          </div>
        )}

        {!!displayableFollows?.length && (
          <AvatarList
            avatarData={displayableFollows?.map(
              ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } = {}, active }) => ({
                initials,
                name,
                organizationName,
                avatar,
                avatarColor,
                active,
                notificationProps: {
                  intent: active ? 'success' : 'danger',
                  icon: active ? 'thumbs-up' : 'thumbs-down',
                  size: 'xl',
                  position: 'bottom'
                }
              })
            )}
          />
        )}
      </CardSection>
    </Card>
  );
};
