import React, { useEffect, useState } from 'react';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { useGetOppQuery } from 'api/oppsApi';
import { useCreateOppInteractionMutation } from 'api/oppInteractionsApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { ContactsCard } from 'app/molecules/ContactsCard/ContactsCard';
import { AddressesCard } from 'app/molecules/AddressesCard/AddressesCard';
import { OppDetails } from 'app/organisms/OppDetails/OppDetails';
import { OppDetailsActions } from 'app/organisms/OppDetailsActions/OppDetailsActions';
import { OppDetailsProviders } from 'app/organisms/OppDetailsProviders/OppDetailsProviders';
import { OppDetailsProviderContacts } from 'app/organisms/OppDetailsProviderContacts/OppDetailsProviderContacts';
import { OppWorkspaces } from 'app/organisms/OppWorkspaces/OppWorkspaces';
import { SearchDrawerNextPrevButtons } from 'app/organisms/SearchDrawerNextPrevButtons/SearchDrawerNextPrevButtons';
import { Loading } from 'app/atoms/Loading/Loading';
import { QuestionsAndAnswers } from 'app/molecules/QuestionsAndAnswers/QuestionsAndAnswers';
import { useSearchMutation } from 'api/oppsApi';
import { useMetaTags } from 'app/hooks/useMetaTags';
import { NotAuthorized } from 'app/molecules/NotAuthorized/NotAuthorized';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';
import { OppShow } from 'types/__generated__/GovlyApi';
import { useAppDrawerStore } from 'app/organisms/AppDrawer/useAppDrawerStore';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { useSearchDrawerNextPrevButtons } from 'app/organisms/SearchDrawerNextPrevButtons/useSearchDrawerNextPrevButtons';
import { Button, Tooltip } from '@blueprintjs/core';
import { useAppDrawerSubNavTab } from 'app/organisms/AppDrawer/useAppDrawerSubNavTab';
import { DrawerType } from 'app/organisms/AppDrawer/constants';
import { OppWorkspacesTable } from 'app/organisms/OppWorkspacesTable/OppWorkspacesTable';
import { OppAttachmentsTable } from 'app/organisms/OppAttachmentsTable/OppAttachmentsTable';

export const showOppDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  ['/opportunities', '/worklist/workspaces', '/worklist/team_workspaces'].includes(window.location.pathname) &&
  drawerType === 'opportunities' &&
  drawerId;

export const OppDrawerTitle = ({ id }: { id: string }) => {
  const { data, isLoading, isError } = useGetOppQuery({ id });
  useMetaTags({ title: data?.displayName ?? 'Govly' }, [data?.displayName]);

  if (isLoading) return <Loading type="flex-row" />;

  if (isError) {
    return null;
  }

  return (
    <div>
      <LinkTag to={`/opportunities/${id}`} target="_blank">
        {data?.displayName || `Opportunity #${id}`}
      </LinkTag>
      <dd>{data?.title}</dd>
    </div>
  );
};

export const OppDrawerBody = ({ id }: { id: string }) => {
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');

  const tab = useAppDrawerSubNavTab('opportunities');

  const [loadedId, setLoadedId] = useState<string | undefined>();
  const { data, isFetching, isError } = useGetOppQuery({ id });
  const [createOppInteraction] = useCreateOppInteractionMutation();

  useEffect(() => {
    createOppInteraction({ oppId: id, viewed: true, async: true });
  }, [id, createOppInteraction]);

  useEffect(() => {
    if (loadedId !== id && !isFetching) {
      setLoadedId(id);
    }
  }, [id, loadedId, isFetching]);

  useEffect(() => {
    if (oppShowNavEnabled) {
      useAppDrawerStore.setState({
        rightElement: <PrevNext id={id} />
      });
    }

    return () => {
      useAppDrawerStore.setState({ rightElement: undefined });
    };
  }, [id, oppShowNavEnabled]);

  if (loadedId !== id) return <AppLoading />;

  if (isError) {
    return <NotAuthorized />;
  }

  return (
    <Main className="max-w-full bg-gray-50 py-12">
      {oppShowNavEnabled ? null : (
        <SearchDrawerNextPrevButtons
          id={id}
          mutation={useSearchMutation}
          resource="opportunities"
          cacheKey="opp-search"
        />
      )}

      {match(tab)
        .with('Overview', () => data && <OverviewTab id={id} data={data} />)
        .with('Workspaces', () => data && <WorkspacesTab id={id} />)
        .with('Attachments', () => data && <AttachmentsTab id={id} />)
        .otherwise(() => null)}
    </Main>
  );
};

const OverviewTab = ({ id, data }: { id: string; data: OppShow }) => {
  const { latestData, contacts, recordLocations = [], addresses } = data;
  const locations = recordLocations.length > 0 ? recordLocations : addresses;
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');

  return (
    <>
      <MainColumn className="lg:hidden">
        <OppDetailsActions id={id} showDrawerButtons />
        <OppDetails id={id} />
        {oppShowNavEnabled ? null : <OppWorkspaces id={id} />}
        <OppDetailsProviderContacts id={id} className="lg:hidden" />
        <ContactsCard contacts={contacts || []} />
        <AddressesCard addresses={locations} />
      </MainColumn>
      <MainColumn columnSpan={8} className="hidden lg:block">
        <OppDetails id={id} />
        <ContactsCard contacts={contacts || []} />
        <QuestionsAndAnswers questions={latestData?.questions || []} />
        <AddressesCard addresses={locations} />
      </MainColumn>
      <MainColumn columnSpan={4} className="hidden lg:block">
        <OppDetailsActions id={id} showDrawerButtons />
        {oppShowNavEnabled ? null : <OppWorkspaces id={id} />}
        <OppDetailsProviderContacts id={id} />
        <OppDetailsProviders id={id} />
      </MainColumn>
    </>
  );
};

const WorkspacesTab = ({ id }: { id: string }) => {
  return (
    <MainColumn>
      <OppWorkspacesTable id={id} />
    </MainColumn>
  );
};

const AttachmentsTab = ({ id }: { id: string }) => {
  return (
    <MainColumn>
      <OppAttachmentsTable id={id} />
    </MainColumn>
  );
};

const PrevNext = ({ id }: { id: string }) => {
  const { nextIdLink, prevIdLink, onClickNext, onClickPrev, hasNextPrev } = useSearchDrawerNextPrevButtons({
    id,
    resource: 'opportunities',
    cacheKey: 'opp-search',
    mutation: useSearchMutation
  });

  if (!hasNextPrev) return null;

  return (
    <div className="flex justify-between gap-x-2">
      <Tooltip content="Previous Opportunity" placement="bottom">
        {prevIdLink ? (
          <Link to={prevIdLink} className="no-underline">
            <Button icon="arrow-left" aria-label="Prev" />
          </Link>
        ) : (
          <Button
            {...(onClickPrev ? { onClick: onClickPrev } : { disabled: true })}
            icon="arrow-left"
            aria-label="Prev"
          />
        )}
      </Tooltip>

      <Tooltip content="Next Opportunity" placement="bottom">
        {nextIdLink ? (
          <Link to={nextIdLink} className="no-underline">
            <Button icon="arrow-right" aria-label="Next" />
          </Link>
        ) : (
          <Button
            icon="arrow-right"
            aria-label="Next"
            {...(onClickNext ? { onClick: onClickNext } : { disabled: true })}
          />
        )}
      </Tooltip>
    </div>
  );
};
