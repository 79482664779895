import { useGetAwardQuery } from 'api/awardsApi';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { AwardPageContents } from 'app/organisms/AwardPageContents/AwardPageContents';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const AwardPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: award, isLoading } = useGetAwardQuery({ id: id ?? '' }, { skip: !id });

  React.useEffect(() => {
    if (!id || (!isLoading && !award)) {
      navigate('/awards', { replace: true });
    }
  }, [award, id, isLoading, navigate]);

  if (!id) return <NotFound />;

  return <AwardPageContents id={id} className="py-4" />;
};
