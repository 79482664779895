import React from 'react';
import { DateTime } from 'luxon';
import { Tag, TagProps } from '@blueprintjs/core';

import { formatTime } from 'app/lib/dates';

type Props = TagProps & {
  cancelledAt?: string;
  respondBy?: string;
  awardedAt?: string;
  hidePrefix?: boolean;
};

export const OppExpirationStatus = ({ cancelledAt, respondBy, awardedAt, hidePrefix, ...rest }: Props) => {
  if (cancelledAt) {
    return (
      <Tag {...rest} intent="danger" minimal>
        Canceled
      </Tag>
    );
  }

  let prefix = '';
  if (awardedAt) {
    return (
      <Tag {...rest} intent="success" minimal>
        {`Awarded ${formatTime(awardedAt)}`}
      </Tag>
    );
  }

  if (respondBy) {
    const respondByDate = DateTime.fromISO(respondBy);
    const expired = respondByDate.toJSDate() < new Date();
    if (expired) {
      if (!hidePrefix) prefix = 'Expired: ';
      return (
        <Tag {...rest} intent="danger" minimal>
          {`${prefix} ${respondByDate.toLocaleString(DateTime.DATETIME_SHORT)}`}
        </Tag>
      );
    }

    if (!hidePrefix) prefix = 'Expires: ';
    return (
      <Tag {...rest} minimal intent="success">
        {`${prefix} ${respondByDate.toLocaleString(DateTime.DATETIME_SHORT)}`}
      </Tag>
    );
  }

  if (!hidePrefix) prefix = 'Expiration';

  return (
    <Tag {...rest} intent="warning" minimal>
      {`${prefix} Unknown`}
    </Tag>
  );
};
