import React from 'react';
import { useFormikContext } from 'formik';
import { Button, FormGroup } from '@blueprintjs/core';

import { AWARD_SEARCH_CACHE_KEY, useAwardSearchMutation } from 'api/awardsApi';
import { SearchQueryInput } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { defaultValues } from 'app/hooks/search/useAwardSearchCache';
import { H2 } from 'app/atoms/Typography/Typography';
import { SavedSearchSelectInput } from 'app/organisms/SavedSearchSelectInput/SavedSearchSelectInput';
import { SavedSearchControls } from 'app/organisms/SavedSearchControls/SavedSearchControls';
import { useRemoveSavedSearchIdParam } from 'app/hooks/useRemoveSavedSearchIdParam';

export const AwardSearchQuery = () => {
  const { setValues, submitForm } = useFormikContext();
  const removeSavedSearchIdParam = useRemoveSavedSearchIdParam();

  const [_search, { isLoading }] = useAwardSearchMutation({
    fixedCacheKey: AWARD_SEARCH_CACHE_KEY
  });

  return (
    <div className="grid grid-cols-1 divide-y dark:divide-gray-600">
      <div className="flex items-center justify-between pb-6">
        <span className="flex items-center gap-2">
          <H2>Award Search</H2>
        </span>
        <LinkTag
          tag="button"
          onClick={e => {
            e.preventDefault();
            setValues(defaultValues);
            removeSavedSearchIdParam();
            submitForm();
          }}
        >
          Reset search
        </LinkTag>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <SavedSearchSelectInput searchableType="USASpendingAward" />
        <FormGroup label={<LabelSpan showEmpty />} className="m-0 pb-6 pt-0 sm:pt-6" contentClassName="mt-2">
          <div className="flex min-w-max justify-end space-x-2">
            <SavedSearchControls searchableType="USASpendingAward" />
            <Button data-test="award-search-button" large loading={isLoading} type="submit" intent="primary">
              Search
            </Button>
          </div>
        </FormGroup>
      </div>
      <SearchQueryInput
        label="Search Terms"
        name="query"
        className="m-0 pt-6"
        loading={isLoading}
        placeholder={`"(nvidia or veritas) not(laptop)"`}
      />
    </div>
  );
};
