import React, { useState } from 'react';
import { Tag, Tooltip, Dialog, Callout } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { useGetOppQuery, useShareOppMutation } from 'api/oppsApi';
import { ShareViaEmailForm } from 'app/organisms/ShareViaEmailForm/ShareViaEmailForm';
import { useCreateOppInteractionMutation } from 'api/oppInteractionsApi';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { DescriptionListLabel } from 'app/molecules/DescriptionList/DescriptionList';
import { LabelsDescriptionListField } from 'app/organisms/LabelsDescriptionListField/LabelsDescriptionListField';
import { WorkspaceFollowers } from 'app/organisms/WorkspaceFollowers/WorkspaceFollowers';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppWorkspaceWorkflowStage } from 'app/organisms/OppWorkspaceWorkflowStage/OppWorkspaceWorkflowStage';

type Props = {
  id: string;
  showDrawerButtons?: boolean;
};

export const OppDetailsActions = ({ id, showDrawerButtons = false }: Props) => {
  const { trackEvent } = useEventTracking();
  const [isSharing, setIsSharing] = useState(false);
  const [shareOpp, { isLoading: isSubmittingShare }] = useShareOppMutation();
  const { data: opp, isLoading } = useGetOppQuery({ id });
  const [follow, { isLoading: isFollowing }] = useCreateOppInteractionMutation();
  const [ignore, { isLoading: isIgnoring }] = useCreateOppInteractionMutation();

  if (isLoading || !opp) return <Loading type="condensed-card" />;

  const {
    title,
    displayName,
    labels,
    isProvider,
    providerPartnerships = [],
    oppInteraction: interaction,
    contractVehicle: { public: isPublic } = {}
  } = opp;
  const defaultWorkspace = opp?.workspaces.find(workspace => workspace.organizationDefault);

  let followTagText = 'Follow';
  if (interaction?.followedAt) {
    followTagText = 'Following';
  } else if (isFollowing) {
    followTagText = 'Saving...';
  }

  let ignoreTagText = 'Not Interested';
  if (isIgnoring) ignoreTagText = 'Saving...';

  const sharedTo = interaction?.sharedTo?.length ? interaction.sharedTo : [];
  return (
    <>
      {!isProvider && !isPublic && !!providerPartnerships.length && (
        <Callout
          className="rounded"
          intent="primary"
          title="You can view this opportunity because one or more of your partners is sharing it with you."
        >
          {`Work with ${
            providerPartnerships.length === 1
              ? providerPartnerships[0].organization?.name
              : providerPartnerships
                  .map(({ organization: { name } = {} }) => name)
                  .slice(0, -1)
                  .join(',') +
                ' or ' +
                providerPartnerships.slice(-1)?.[0]?.organization?.name
          } to respond to this opportunity.`}
        </Callout>
      )}
      <Card>
        <CardBody className="sm:py-3 sm:px-6">
          <dl className="grid grid-cols-2 gap-y-4">
            <div className="col-span-2 flex flex-col space-y-2">
              <DescriptionListLabel>Followers (Default Workspace)</DescriptionListLabel>
              <div className="space-y-2">
                <WorkspaceFollowers
                  formTitle="Add Followers to Opportunity"
                  workspace={defaultWorkspace}
                  type="Opp"
                  resource={opp}
                />
                <Tag
                  className="mr-2"
                  icon="thumbs-up"
                  minimal
                  round
                  interactive
                  intent={interaction?.followedAt ? 'success' : 'none'}
                  onClick={async () => {
                    const followed = !interaction?.followedAt;
                    await follow({ oppId: id, followed });
                    trackEvent({
                      object: 'opp',
                      action: followed ? 'followed' : 'unfollowed',
                      properties: {
                        resourceId: id
                      }
                    });
                  }}
                >
                  {followTagText}
                </Tag>
                <Tag
                  icon="thumbs-down"
                  minimal
                  round
                  interactive
                  intent={interaction?.ignoredAt ? 'warning' : 'none'}
                  onClick={async () => {
                    const ignored = !interaction?.ignoredAt;
                    await ignore({ oppId: id, ignored });
                    trackEvent({
                      object: 'opp',
                      action: ignored ? 'ignored' : 'unignored',
                      properties: {
                        resourceId: id
                      }
                    });
                  }}
                >
                  {ignoreTagText}
                </Tag>
              </div>
            </div>
            <LabelsDescriptionListField taggableId={id} taggableType="Opp" labels={labels} />

            {defaultWorkspace ? (
              <DescriptionListField
                cols={2}
                label="Stage (Default Workspace)"
                value={<OppWorkspaceWorkflowStage workspaceId={defaultWorkspace.id} asTag />}
              />
            ) : null}

            <DescriptionListField
              label="Actions"
              value={
                <div className="flex flex-wrap gap-2">
                  <Tooltip
                    content={sharedTo.length ? `Previously shared to: ${sharedTo.join(', ')}` : ''}
                    disabled={!sharedTo.length}
                  >
                    <Tag
                      intent={sharedTo.length ? 'primary' : 'none'}
                      interactive
                      round
                      minimal
                      icon="envelope"
                      onClick={() => setIsSharing(true)}
                      data-test="share-via-email-button"
                    >{`Share via Email`}</Tag>
                  </Tooltip>
                  {showDrawerButtons && (
                    <Tooltip content="Click to view opportunity in a new tab">
                      <LinkTag to={`/opportunities/${id}`} target="_blank">
                        <Tag interactive round minimal icon="share">
                          Open in new tab
                        </Tag>
                      </LinkTag>
                    </Tooltip>
                  )}
                </div>
              }
              cols={2}
            />
          </dl>
          <Dialog isOpen={isSharing} className="min-w-[50%] p-0">
            <ShareViaEmailForm
              title="Share Opportunity"
              onCancel={() => setIsSharing(false)}
              initialValues={{ subject: `${displayName}: ${title}`, id }}
              isSubmitting={isSubmittingShare}
              onSubmit={values => shareOpp(values).unwrap()}
            />
          </Dialog>
        </CardBody>
      </Card>
    </>
  );
};
