import React, { useEffect, useState } from 'react';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';
import { useWorkspaceFromSearchDecorator } from 'api/oppsApi';
import { useGetOppWorkspaceQuery, useAddWorkspaceInteractionMutation } from 'api/oppWorkspacesApi';
import { OppWorkspaceTimeline } from 'app/organisms/OppWorkspaceTimeline/OppWorkspaceTimeline';
import { OppWorkspaceCard } from 'app/organisms/OppWorkspaceCard/OppWorkspaceCard';
import { OppWorkspaceOppDetails } from 'app/organisms/OppWorkspaceOppDetails/OppWorkspaceOppDetails';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { OppWorkspaceWorkflowStage } from 'app/organisms/OppWorkspaceWorkflowStage/OppWorkspaceWorkflowStage';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { OppWorkspaceCommentCreateForm } from 'app/organisms/OppWorkspaceCommentCreateForm/OppWorkspaceCommentCreateForm';
import { OppWorkspaceQuoteForm } from 'app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteForm';
import { Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { useWorkspaceQuotesWithPolling } from 'app/organisms/OppWorkspaceQuoteForm/useWorkspaceQuotesWithPolling';
import { cn } from 'app/lib/cn';
import { match } from 'ts-pattern';

const TABS = [
  {
    label: 'Workspace',
    value: 'Workspace'
  },
  {
    label: 'Opportunity',
    value: 'Opportunity'
  }
];

export const OppWorkspacePageContents = ({
  id,
  inDrawer = false,
  breadcrumbs,
  tab
}: {
  id: string;
  inDrawer?: boolean;
  breadcrumbs?: React.ReactNode;
  tab?: string;
}) => {
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');
  const workspaceImprovementsEnabled = useFeatureFlag('workspace-improvements');

  const [tabState, setCurrentTab] = useState(tab ?? 'Workspace');
  const currentTab = tab ?? tabState;

  const {
    data: fetchedWorkspace,
    isLoading: workspaceLoading,
    isSuccess: workspaceLoaded,
    isError
  } = useGetOppWorkspaceQuery({ id });
  const { data: currentUser } = useGetCurrentUserQuery();

  const workspaceFromSearch = useWorkspaceFromSearchDecorator({ id });
  const workspace = workspaceFromSearch || fetchedWorkspace;
  const oppId = workspace?.workableId;

  const [addWorkspaceInteraction] = useAddWorkspaceInteractionMutation();

  const { data: quotes } = useWorkspaceQuotesWithPolling({ workspaceId: id });

  useEffect(() => {
    addWorkspaceInteraction({ id });
  }, [addWorkspaceInteraction, id]);

  if (workspaceLoading && !workspace) {
    return <AppLoading />;
  }

  if (isError || !workspace) {
    return <NotFound />;
  }

  const pane = match({ workspaceLoaded, currentTab })
    .with({ workspaceLoaded: true, currentTab: 'Opportunity' }, () => (
      <OppWorkspaceOppDetails workspaceIdProp={workspace.id} oppIdProp={oppId} />
    ))
    .with({ workspaceLoaded: true }, () => (
      <div className="space-y-4">
        {currentUser?.compliancePreventUploads || workspaceImprovementsEnabled ? null : (
          <OppWorkspaceQuoteForm quotes={quotes ?? []} />
        )}

        <OppWorkspaceCommentCreateForm oppId={oppId ?? ''} workspaceId={workspace.id ?? ''} />
        <OppWorkspaceTimeline oppId={oppId} workspaceId={workspace.id} quotes={quotes ?? []} />
      </div>
    ))
    .otherwise(() => <Loading />);

  return (
    <Main gridClassName="min-h-screen" className="max-w-full bg-gray-50 relative">
      {oppShowNavEnabled ? null : (
        <MainColumn className="space-y-2">
          {breadcrumbs && <div className="lg:order-first lg:flex lg:justify-between lg:space-y-0">{breadcrumbs}</div>}

          <div className="inline-block">
            <Select
              items={TABS}
              onItemSelect={i => setCurrentTab(i.value)}
              itemRenderer={(item, opts) => (
                <MenuItem
                  key={item.label}
                  text={item.label}
                  active={item.value === currentTab}
                  onClick={opts.handleClick}
                />
              )}
              popoverProps={{
                minimal: true,
                modifiers: {
                  offset: { enabled: true }
                }
              }}
            >
              <Button outlined rightIcon="caret-down" className="w-32 justify-between">
                {currentTab}
              </Button>
            </Select>
          </div>
        </MainColumn>
      )}

      <MainColumn columnSpan={8}>
        <div>
          <OppWorkspaceWorkflowStage
            workspaceId={workspace.id}
            className={cn('hidden mb-6', inDrawer ? 'xl:block' : 'lg:block')}
          />
          {pane}
        </div>
      </MainColumn>

      {fetchedWorkspace && (
        <MainColumn
          columnSpan={4}
          className={cn(
            'self-start sticky overflow-hidden -m-px p-px',

            inDrawer ? 'top-[94px]' : 'top-[20px]'
          )}
        >
          <OppWorkspaceCard
            workspace={fetchedWorkspace}
            cardListProps={{
              wrapperClassName: cn(
                'overflow-y-auto h-full pb-10',
                inDrawer ? 'max-h-[calc(100vh_-_75px_-_40px)]' : 'max-h-[calc(100vh_-_128px)]'
              )
            }}
          />
        </MainColumn>
      )}
    </Main>
  );
};
