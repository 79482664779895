import React, { useCallback, useMemo, useState } from 'react';
import { useReward } from 'react-rewards';
import { AnchorButton, Menu, MenuItem, Popover, Tooltip } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';
import { createColumnHelper, Row, RowSelectionState } from '@tanstack/react-table';

import {
  WorkspaceWorklist,
  OrganizationUserCurrentOrg,
  OrganizationUserCurrentUser
} from 'types/__generated__/GovlyApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { useGetWorklistQuery } from 'api/worklistApi';
import { useBulkUpdateOppWorkspacesMutation } from 'api/oppWorkspacesApi';

import { openDrawerOrNewTab } from 'app/lib/navigation';
import { successToast, errorToast } from 'app/lib/toaster';

import { useDeviceWidth } from 'app/hooks/useDeviceWidth';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { OppUpdateTags } from 'app/molecules/OppUpdateTags/OppUpdateTags';
import { WorkflowStageTag } from 'app/molecules/WorkflowStageTag/WorkflowStageTag';
import {
  makeGovlyTableRowSelectionColumn,
  ROWS_SELECTION_ID
} from 'app/molecules/GovlyTable/makeGovlyTableRowSelectionColumn';

import { OppWorkspaceHeader } from 'app/organisms/OppWorkspaceHeader/OppWorkspaceHeader';
import { OppExpirationStatus } from 'app/organisms/_Opp/OppExpirationStatus';
import { LabelList } from 'app/organisms/LabelList/LabelList';
import { SetAsideTag } from 'app/organisms/SetAsideTag/SetAsideTag';

export type WorkspaceFormAttrs = {
  id: string;
  basicInputs: boolean;
  stageInputs: boolean;
  followersInputs: boolean;
  organizationDefault?: boolean;
};

const columnHelper = createColumnHelper<WorkspaceWorklist>();

const filterWorkspaces = function (row: Row<WorkspaceWorklist>, columnId: string, filterValue: string) {
  if (!filterValue) return true; // If no filter value, include all rows

  const query = filterValue.toLowerCase().trim();

  const { displayName, title, setAside } = row.original.oppData;

  // Check for filtering based on 'name' column
  if (columnId === 'name') {
    const candidates = [row.original.name, displayName];
    return candidates.some(candidate => candidate?.toLowerCase()?.includes(query));
  }

  // Check for filtering based on 'oppData.title' column
  if (columnId === 'opp') {
    let setAsidesString;

    if (setAside) {
      setAsidesString = `${setAside.code} ${setAside.name}`;
    }

    const candidates = [title, displayName, setAsidesString];
    return candidates.some(candidate => candidate?.toLowerCase()?.includes(query));
  }

  return false; // Return false if no matching columnIds
};

const filterLabels = function (row: Row<WorkspaceWorklist>, _columnId: string, filterValue: string) {
  if (!filterValue) return true; // If no filter value, include all rows

  const query = filterValue.toLowerCase().trim();

  // Assuming labelsFromRow is a function that extracts labels from the row
  const labels = labelsFromRow(row);

  return labels.some(label => label.name.toLowerCase().includes(query));
};

const filterWorkflowStage = function (row: Row<WorkspaceWorklist>, _columnId: string, filterValue: string) {
  if (!filterValue) return true; // If no filter value, include all rows

  const query = filterValue.toLowerCase().trim();
  const decoratedQuery = query.split(' ').join('_');

  const stage = row.original.workflowStage.toLowerCase();
  return stage.includes(query) || stage.includes(decoratedQuery);
};

const filterFollows = function (row: Row<WorkspaceWorklist>, _columnId: string, filterValue: string) {
  if (!filterValue) return true;

  const follows = 'follows' in row.original ? (row.original.follows ?? []) : [];
  const searchTerm = filterValue.toLowerCase();

  return follows.some(
    f =>
      f.organizationUser?.name?.toLowerCase().includes(searchTerm) ||
      f.organizationUser?.email.toLowerCase().includes(searchTerm)
  );
};

export const useWorklist = () => {
  const { isMobile, isTablet } = useDeviceWidth();
  const [_searchParams, setSearchParams] = useSearchParams();

  const [workspaceFormAttrs, setWorkspaceFormAttrs] = useState<WorkspaceFormAttrs | null>(null);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [filterInTransition, setFilterTransition] = useState(false);
  const [workspaceFilter, setWorkspaceFilter] = useState<'active' | 'inactive'>('active');

  const [bulkUpdateOppWorkspaces, { isLoading: isUpdatingWorklistState }] = useBulkUpdateOppWorkspacesMutation();
  const { data: currentUserData } = useGetCurrentUserQuery();
  const { data: organizationUserData = [], isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });

  const { reward: updateReward } = useReward('worklist-tab', 'confetti', {
    angle: 45
  });

  const organizationUsers = useMemo(() => organizationUserData as OrganizationUserCurrentOrg[], [organizationUserData]);
  const viewer = useMemo(() => (currentUserData as OrganizationUserCurrentUser) ?? {}, [currentUserData]);
  const team = useMemo(
    () => [
      viewer,
      ...organizationUsers.filter(
        organizationUser => organizationUser.primaryRole !== 'govly_support' && organizationUser.id !== viewer.id
      )
    ],
    [organizationUsers, viewer]
  );

  const [worklistOwnerId, setWorklistOwnerId] = useState(viewer.id);
  const viewingOwnWorklist = viewer.id === worklistOwnerId;

  const worklistQueryParams = {
    filter: workspaceFilter,
    worklistOwnerId: viewingOwnWorklist ? undefined : worklistOwnerId
  };

  const {
    data: workspaces = [],
    isLoading: workspacesLoading,
    isFetching: workspacesFetching
  } = useGetWorklistQuery(worklistQueryParams, { refetchOnMountOrArgChange: true });

  const columnVisibility = useMemo(() => {
    return {
      actions: viewingOwnWorklist,
      [ROWS_SELECTION_ID]: viewingOwnWorklist,
      workflowStage: !isMobile,
      opp: !isMobile && !isTablet
    };
  }, [viewingOwnWorklist, isMobile, isTablet]);

  const updateWorkspace = useCallback(
    async (payload: { ids: string[]; unfollow?: boolean; removeFromWorklist?: boolean }) => {
      try {
        await bulkUpdateOppWorkspaces(payload);

        if (payload.removeFromWorklist) {
          updateReward();
        } else {
          successToast();
        }
      } catch (e) {
        errorToast(e);
      }
    },
    [bulkUpdateOppWorkspaces, updateReward]
  );

  const columns = useMemo(
    () => getColumns({ workspaceFilter, setWorkspaceFormAttrs, setSearchParams, updateWorkspace, isMobile, isTablet }),
    [workspaceFilter, setSearchParams, updateWorkspace, isMobile, isTablet]
  );

  return {
    columns,
    columnVisibility,
    updateReward,
    worklistOwnerId,
    setWorklistOwnerId,
    team,
    teamLoading,
    setWorkspaceFormAttrs,
    workspaceFormAttrs,
    selectedRows,
    setSelectedRows,
    filterInTransition,
    setFilterTransition,
    setWorkspaceFilter,
    workspacesFetching,
    workspacesLoading,
    isUpdatingWorklistState,
    workspaceFilter,
    workspaces,
    viewer,
    bulkUpdateOppWorkspaces,
    viewingOwnWorklist,
    onActiveFilter: workspaceFilter === 'active',
    onInactiveFilter: workspaceFilter === 'inactive'
  };
};

const labelsFromRow = (row: Row<WorkspaceWorklist>) => {
  const set = new Set();
  const allLabels = row.original.labels ?? [];
  return allLabels.filter(l => !set.has(l.id) && set.add(l.id));
};

const getColumns = ({
  setSearchParams,
  workspaceFilter,
  setWorkspaceFormAttrs,
  updateWorkspace,
  isMobile,
  isTablet
}: {
  setSearchParams: (params: Record<string, string>) => void;
  workspaceFilter: string;
  setWorkspaceFormAttrs: (attrs: WorkspaceFormAttrs) => void;
  updateWorkspace: (payload: { ids: string[]; unfollow?: boolean; removeFromWorklist?: boolean }) => void;
  isMobile: boolean;
  isTablet: boolean;
}) => {
  return [
    makeGovlyTableRowSelectionColumn({ columnHelper }),
    columnHelper.accessor('oppData.title', {
      id: 'opp',
      header: 'Opportunity',
      sortingFn: 'basic',
      filterFn: filterWorkspaces,
      cell: e => (
        <div className="flex flex-col gap-1">
          <LinkTag
            tag="a"
            onClick={event => {
              openDrawerOrNewTab(event, `/opportunities/${e.row.original.workableId}`, () =>
                setSearchParams({ drawerType: 'opportunities', drawerId: e.row.original.workableId })
              );
            }}
            className=" font-medium no-underline"
            data-test="opportunity-title"
          >
            <NewTabTooltip title="View Opportunity">
              <dt className="whitespace-normal text-base font-semibold">{e.row.original.oppData.title}</dt>
            </NewTabTooltip>
          </LinkTag>
          <dt className="text-sm font-semibold text-gray-500">
            <OppUpdateTags updates={e.row.original.updates ?? []} expiringSoon={e.row.original.expiringSoon} />
            <Tooltip lazy content={e.row.original.oppData.displayName} className="w-full">
              <div className="w-full truncate">{e.row.original.oppData.displayName}</div>
            </Tooltip>
          </dt>

          <dt>
            <SetAsideTag code={e.row.original.oppData.setAside?.code} />
          </dt>
        </div>
      )
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      sortingFn: 'basic',
      filterFn: filterWorkspaces,
      maxSize: 16,
      cell: e => {
        const path = `/opportunities/${e.row.original.workableId}/workspaces/${e.row.original.id}`;

        return (
          <div>
            <OppWorkspaceHeader
              id={e.row.original.id}
              oppId={e.row.original.workableId}
              name={e.row.original.name}
              organizationDefault={e.row.original.organizationDefault}
              onClick={event => {
                openDrawerOrNewTab(event, path, () =>
                  setSearchParams({
                    drawerType: 'workspaces',
                    drawerId: e.row.original.id
                  })
                );
              }}
              showLink
              updates={(e.row.original.updates ?? []).filter(u => u.actionTargetType === 'Workspace')}
              visibleId={isMobile || isTablet ? e.row.original.oppData.displayName : undefined}
              createdByOrganization={e.row.original.createdByOrganization}
              className="max-w-auto w-fit"
            />
            {isMobile || isTablet ? (
              <OppUpdateTags updates={e.row.original.updates ?? []} expiringSoon={e.row.original.expiringSoon} />
            ) : null}
          </div>
        );
      }
    }),
    columnHelper.accessor('follows', {
      header: 'Followers',
      sortingFn: 'basic',
      filterFn: filterFollows,
      cell: e => {
        const follows = 'follows' in e.row.original ? (e.row.original.follows ?? []) : [];

        return (
          <AvatarList
            limit={2}
            avatarData={follows.map(
              ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } = {}, active }) => ({
                initials,
                name,
                organizationName,
                avatar,
                avatarColor,
                active,
                notificationProps: {
                  intent: active ? 'success' : 'danger',
                  icon: active ? 'thumbs-up' : 'thumbs-down',
                  size: 'xl',
                  position: 'bottom'
                }
              })
            )}
          />
        );
      }
    }),
    columnHelper.accessor(e => e.oppData?.respondBy ?? '', {
      id: 'respondBy',
      header: 'Respond By',
      sortingFn: 'basic',
      filterFn: 'dateStringFilter',
      cell: e => {
        const { respondBy, awardedAt, cancelledAt } = e.row.original.oppData;
        return <OppExpirationStatus respondBy={respondBy} awardedAt={awardedAt} cancelledAt={cancelledAt} hidePrefix />;
      }
    }),
    columnHelper.accessor('workflowStage', {
      header: 'Stage',
      sortingFn: 'basic',
      filterFn: filterWorkflowStage,
      cell: e => (
        <WorkflowStageTag
          workflowStage={e.row.original.workflowStage}
          interactive
          onClick={() =>
            setWorkspaceFormAttrs({
              id: e.row.original.id,
              basicInputs: false,
              stageInputs: true,
              followersInputs: false
            })
          }
        />
      )
    }),
    columnHelper.accessor('labels', {
      header: 'Labels',
      sortingFn: 'basic',
      filterFn: filterLabels,
      cell: e => {
        const labels = labelsFromRow(e.row);
        return <LabelList labels={labels} />;
      }
    }),
    columnHelper.display({
      id: 'actions',
      cell: e => {
        const space = e.row.original;
        const markAsActive = workspaceFilter === 'inactive';
        return (
          <Popover
            className="self-end"
            content={
              <Menu key="menu">
                <MenuItem
                  icon={markAsActive ? 'undo' : 'tick'}
                  onClick={() => updateWorkspace({ removeFromWorklist: !markAsActive, ids: [space.id] })}
                  text={markAsActive ? 'Mark as Active' : 'Check off my list'}
                />
                <MenuItem
                  icon="plus"
                  onClick={() =>
                    setWorkspaceFormAttrs({
                      id: space.id,
                      basicInputs: false,
                      stageInputs: false,
                      followersInputs: true
                    })
                  }
                  text="Add/Remove Followers"
                />
                <MenuItem
                  icon="edit"
                  onClick={() =>
                    setWorkspaceFormAttrs({
                      id: space.id,
                      basicInputs: true,
                      stageInputs: true,
                      organizationDefault: space.organizationDefault,
                      followersInputs: false
                    })
                  }
                  text="Edit Workspace"
                />
                <MenuItem
                  icon="thumbs-down"
                  onClick={() => updateWorkspace({ unfollow: true, ids: [space.id] })}
                  text="Stop following"
                />
              </Menu>
            }
          >
            <AnchorButton icon="more" />
          </Popover>
        );
      }
    })
  ];
};
