import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { useSearchDrawerNextPrevButtons } from 'app/organisms/SearchDrawerNextPrevButtons/useSearchDrawerNextPrevButtons';
import { useSearchMutation } from 'api/oppsApi';
import { useContactSearchMutation } from 'api/contactsApi';

type SearchDrawerNextPrevButtonsProps = {
  id: string;
  mutation: typeof useSearchMutation | typeof useContactSearchMutation;
  resource: string;
  cacheKey: string;
};

export const SearchDrawerNextPrevButtons = ({ id, mutation, resource, cacheKey }: SearchDrawerNextPrevButtonsProps) => {
  const { nextIdLink, prevIdLink, onClickNext, onClickPrev, hasNextPrev } = useSearchDrawerNextPrevButtons({
    id,
    mutation,
    resource,
    cacheKey
  });

  if (!hasNextPrev) return null;

  return (
    <MainColumn columnSpan={12}>
      <div className="flex justify-between">
        {prevIdLink ? (
          <Link to={prevIdLink} className="no-underline">
            <Button>Prev</Button>
          </Link>
        ) : (
          <Button {...(onClickPrev ? { onClick: onClickPrev } : { disabled: true })}>Prev</Button>
        )}

        {nextIdLink ? (
          <Link to={nextIdLink} className="no-underline">
            <Button>Next</Button>
          </Link>
        ) : (
          <Button {...(onClickNext ? { onClick: onClickNext } : { disabled: true })}>Next</Button>
        )}
      </div>
    </MainColumn>
  );
};
