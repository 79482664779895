import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import { ProviderFeedIndex } from 'types/__generated__/GovlyApi';
import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';

import { openDrawerOrNewTab } from 'app/lib/navigation';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { ProviderFeedLastActive } from 'app/organisms/ProviderFeedLastActive/ProviderFeedLastActive';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

const columnHelper = createColumnHelper<ProviderFeedIndex>();

export const ManagedFeedsTable = () => {
  const { data: managedFeeds = [], isLoading } = useGetProviderFeedsQuery();
  const [_searchParams, setSearchParams] = useSearchParams();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => (
          <LinkTag
            {...{
              tag: 'a',
              onClick: event => {
                event.preventDefault();
                openDrawerOrNewTab(event, `/settings/managed_feeds/${e.row.original.publicId}`, () =>
                  setSearchParams({ drawerType: 'managed_feed', drawerId: e.row.original.publicId })
                );
              }
            }}
          >
            {e.row.original.name}
          </LinkTag>
        )
      }),
      columnHelper.accessor('organization.name', {
        header: 'Provider',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('lastActive', {
        header: 'Last Active',
        sortingFn: 'basic',
        enableColumnFilter: false,
        cell: e => (
          <ProviderFeedLastActive
            lastActive={`${e.row.original.lastActive}`}
            isExpired={e.row.original.contractVehicle.isExpired}
          />
        )
      })
    ],
    [setSearchParams]
  );

  const tableData = useMemo(() => managedFeeds, [managedFeeds]);

  return (
    <GovlyTable
      id="managed_feeds_table"
      columns={columns}
      data={tableData}
      title={<GovlyTableTitle title="Managed Feeds" />}
      subtitle="A list of all the feeds you manage in Govly"
      isLoading={isLoading}
      emptyStateProps={{
        icon: 'warning-sign',
        title: 'No feeds configured',
        description: 'You are not feeding data into Govly.'
      }}
      initialState={{
        sorting: [{ id: 'name', desc: false }],
        pagination: { pageSize: 50 }
      }}
    />
  );
};
