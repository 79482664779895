import React from 'react';
import { Icon } from '@blueprintjs/core';

import { timeAgoIn } from 'app/lib/dates';
import { pluralize } from 'app/lib/strings';

type ProviderFeedLastActiveProps = React.HTMLAttributes<HTMLDivElement> & {
  lastActive?: string;
  isExpired?: boolean;
};

export const ProviderFeedLastActive = ({ isExpired, lastActive, ...rest }: ProviderFeedLastActiveProps) => {
  const daysAgo = Math.round(timeAgoIn({ startISO: lastActive ?? '' }));
  let label = `Last received ${daysAgo} ${pluralize('day', 'days', daysAgo)} ago`;

  if (Number.isNaN(daysAgo)) {
    label = 'This feed has not received data';
  } else if (isExpired === true) {
    label = 'Contract Expired';
  } else if (daysAgo < 1) {
    label = 'Last received today';
  }

  return (
    <div className="my-2" {...rest}>
      <Icon className="mr-1" icon={daysAgo < 8 ? 'tick' : 'warning-sign'} intent={daysAgo < 8 ? 'success' : 'danger'} />
      {label}
    </div>
  );
};
