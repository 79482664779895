import React from 'react';
import { ButtonGroup, EntityTitle, EntityTitleProps, Icon, IconSize, Tooltip, TooltipProps } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';
import { getFileExtensionIconProps } from 'app/lib/file';

type Props = {
  filename: string;
  url: string;
  extension: string;
  tooltipProps?: Partial<TooltipProps>;
  actionsButtonGroup?: React.ReactNode;
} & Partial<EntityTitleProps>;

export const AttachmentEntityTitle = ({
  filename,
  url,
  extension,
  tooltipProps: { className: tooltipClassName, ...tooltipProps } = {},
  actionsButtonGroup,
  ...rest
}: Props) => {
  return (
    <div className="border border-gray-100 py-1 px-2 group flex items-center outline-none h-[34px]">
      <div className="flex-grow min-w-0">
        <EntityTitle
          titleURL={url}
          ellipsize
          title={
            <Tooltip
              content={filename}
              hoverOpenDelay={1000}
              className={cn('max-w-[240px] flex', tooltipClassName)}
              {...tooltipProps}
            >
              <span className="truncate w-full">{filename}</span>
            </Tooltip>
          }
          icon={<Icon size={IconSize.LARGE} {...getFileExtensionIconProps(extension)} />}
          {...rest}
        />
      </div>

      <ButtonGroup minimal className="flex-shrink-0">
        {actionsButtonGroup}
      </ButtonGroup>
    </div>
  );
};
