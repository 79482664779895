import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSearchPagination } from 'app/hooks/useSearchPagination';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { useSearchMutation } from 'api/oppsApi';
import { useContactSearchMutation } from 'api/contactsApi';

export const useSearchDrawerNextPrevButtons = ({
  id,
  mutation,
  resource,
  cacheKey
}: {
  id: string;
  mutation: typeof useSearchMutation | typeof useContactSearchMutation;
  resource: string;
  cacheKey: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const drawerTab = searchParams.get(DRAWER_PARAMS.tab);

  const {
    nextId,
    prevId,
    currentPage = 0,
    totalPages = 0,
    onPageChange
  } = useSearchPagination({
    mutation,
    cacheKey,
    itemId: id
  });

  const hasNextPrev = window.location.pathname === `/${resource}` && (nextId || prevId);

  const getParams = useCallback(
    (id: string) =>
      [
        [DRAWER_PARAMS.type, resource],
        [DRAWER_PARAMS.id, id],
        [DRAWER_PARAMS.tab, drawerTab]
      ]
        .filter(([, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&'),
    [drawerTab, resource]
  );

  const setPageIncrement = useCallback(
    async (increment: number) => {
      const { data: { results = [] } = {} } = await onPageChange(currentPage + increment);
      const newIndex = increment > 0 ? 0 : results.length - 1;
      const params = getParams(results[newIndex]?.id);
      navigate(`/${resource}?${params}`);
    },
    [currentPage, getParams, navigate, onPageChange, resource]
  );

  const getRoute = useCallback((id: string) => `/${resource}?${getParams(id)}`, [getParams, resource]);

  return {
    hasNextPrev,
    prevIdLink: prevId ? `${getRoute(prevId)}` : undefined,
    nextIdLink: nextId ? `${getRoute(nextId)}` : undefined,
    onClickPrev: currentPage > 1 ? () => setPageIncrement(-1) : undefined,
    onClickNext: currentPage < totalPages ? () => setPageIncrement(1) : undefined
  };
};
