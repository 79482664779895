import React, { useMemo } from 'react';
import { Drawer, Button } from '@blueprintjs/core';
import camelCase from 'lodash-es/camelCase';
import { createColumnHelper, AccessorKeyColumnDef } from '@tanstack/react-table';
import { QuoteShow as Quote } from 'types/__generated__/GovlyApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { successToast } from 'app/lib/toaster';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableCSVExportButton } from 'app/molecules/GovlyTable/GovlyTableCSVExportButton';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import {
  useOppWorkspaceStore,
  useOppWorkspaceStoreRef
} from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

type TableData = {
  columns: AccessorKeyColumnDef<Record<string, string>, string>[];
  rows: { [key: string]: string | number }[];
};

function toCSV({ columns, rows }: TableData) {
  const headerValues = columns.map(column => column.header);
  const data = [headerValues, ...rows.map(row => columns.map(column => row[column.accessorKey]))];
  return data;
}

function copyToClipboard(data: TableData) {
  navigator.clipboard.writeText(
    toCSV(data)
      .map(lines => lines.join('\t'))
      .join('\n')
  );

  successToast('Copied to clipboard');
}

const columnHelper = createColumnHelper<Record<string, string>>();

export function OppWorkspaceQuoteDrawer() {
  const { isMobile } = useDeviceWidth();

  const workspaceId = useOppWorkspaceStore(state => state.workspaceId);
  const quote = useOppWorkspaceStore(state => state.quoteToView);
  const storeRef = useOppWorkspaceStoreRef();

  const { data: workspace } = useGetOppWorkspaceQuery({ id: workspaceId });
  const workableDisplayName = workspace?.workableDisplayName;
  const workspaceName = workspace?.name;
  const workableId = workspace?.workableId;

  const getColumns = (lineItemsRaw?: Quote['lineItemsRaw']) => {
    if (!lineItemsRaw) return [];
    const headers = lineItemsRaw[0];

    return headers.map(header => {
      return columnHelper.accessor(camelCase(header.header), {
        header: header.header,
        enableColumnFilter: false
      });
    });
  };

  const getData = (lineItemsRaw?: Quote['lineItemsRaw'], lineItemsTotal?: Quote['lineItemsTotal']) => {
    if (!lineItemsRaw) return [];

    const mappedLineItems = lineItemsRaw.map(lineItem => {
      const row: Record<string, string> = {};
      lineItem.forEach(item => {
        row[camelCase(item.header)] = item.value;
      });
      return row;
    });

    if (lineItemsTotal) {
      const totalHeaders = lineItemsRaw[0].slice(-2);

      const totalRow = {
        [camelCase(totalHeaders[0].header)]: 'Total',
        [camelCase(totalHeaders[1].header)]: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(lineItemsTotal)
      };

      mappedLineItems.push(totalRow);
    }

    return mappedLineItems;
  };

  const data = useMemo(() => {
    return getData(quote?.lineItemsRaw, quote?.lineItemsTotal);
  }, [quote?.lineItemsRaw, quote?.lineItemsTotal]);

  const columns = useMemo(() => {
    return getColumns(quote?.lineItemsRaw);
  }, [quote?.lineItemsRaw]);

  const drawerTitle = (
    <div>
      <LinkTag to={`/opportunities/${workableId}`} target="_blank">
        {workableDisplayName || `Opportunity #${workableId}`}
      </LinkTag>
      <dd>Workspace: {workspaceName}</dd>
    </div>
  );

  const tableTitle = quote?.file ? quote.file.name.replace(/\.[^/.]+$/, '') : '';

  return (
    <Drawer
      isOpen={!!quote}
      onClose={() => storeRef.setState({ quoteToView: undefined })}
      size={isMobile ? '100%' : '85%'}
      position="right"
      title={drawerTitle}
      className="overflow-scroll"
    >
      {quote ? (
        <GovlyTable
          id="quote_drawer"
          columns={columns}
          data={data}
          title={<GovlyTableTitle title={tableTitle} />}
          striped={true}
          rightElement={
            <GovlyTableToolbar>
              <Button icon="clipboard" outlined onClick={() => copyToClipboard({ columns, rows: data })}>
                Copy
              </Button>
              <GovlyTableCSVExportButton filename={tableTitle} />
            </GovlyTableToolbar>
          }
        />
      ) : null}
    </Drawer>
  );
}
