import React, { Suspense } from 'react';
import { lazily } from 'react-lazily';
import { Formik } from 'formik';

import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { DateRangeInput } from 'app/atoms/inputs/DateRangeInput/DateRangeInput';
import { oneMonthAgo, defaultDateRangeEnd } from 'app/lib/dates';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { OrganizationInsightsActivityTable } from 'app/organisms/OrganizationInsightsActivityTable/OrganizationInsightsActivityTable';
import { OrganizationInsightsFollowedTimeline } from 'app/organisms/OrganizationInsightsFollowedTimeline/OrganizationInsightsFollowedTimeline';
import { ReportExportButton } from 'app/organisms/ReportExportButton/ReportExportButton';
import { Loading } from 'app/atoms/Loading/Loading';
import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';

const { OrganizationInsightsFollowed } = lazily(
  () => import('app/organisms/OrganizationInsightsFollowed/OrganizationInsightsFollowed')
);
const { OrganizationInsightsOppInteractionsTimeline } = lazily(
  () => import('app/organisms/OrganizationInsightsOppInteractionsTimeline/OrganizationInsightsOppInteractionsTimeline')
);
const { OrganizationInsightsSharesTable } = lazily(
  () => import('app/organisms/OrganizationInsightsSharesTable/OrganizationInsightsSharesTable')
);

export const OrganizationInsights = () => {
  const { data = [], isLoading } = useGetOrganizationUsersQuery({ view: 'current_org' });
  const organizationUsers = data as OrganizationUserCurrentOrg[];
  const users = organizationUsers.filter(organizationUser => organizationUser.primaryRole !== 'govly_support');

  return (
    // @ts-expect-error Formik requires onSubmit, but we're not using it. We should refactor to use state instead
    <Formik
      initialValues={{
        orgUserId: 'all_users',
        dateRange: [oneMonthAgo(), defaultDateRangeEnd()] as [Date, Date]
      }}
    >
      {({ values }) => (
        <div className="p-4">
          <div className="flex flex-wrap items-end justify-between mb-4">
            <SelectInput
              filterable={false}
              loading={isLoading}
              disabled={isLoading}
              contentClassName="mt-0"
              name="orgUserId"
              items={[
                { value: 'all_users', label: 'All Users' },
                ...users.map(({ name, id }) => ({ value: id, label: name }))
              ]}
            />
            <div className="flex space-x-2">
              <ReportExportButton reportType="Report::OrganizationFollowsReport" filters={values} />
              <DateRangeInput contentClassName="mt-0" name="dateRange" large />
            </div>
          </div>
          <section className="mt-2 space-y-8">
            <OrganizationInsightsActivityTable filters={values} />
            <OrganizationInsightsFollowedTimeline filters={values} />
            <Suspense fallback={<Loading />}>
              <OrganizationInsightsFollowed filters={values} />
            </Suspense>
            <Suspense fallback={<Loading />}>
              <OrganizationInsightsOppInteractionsTimeline
                filters={{ interaction: 'matched_at', ...values }}
                title="Opportunity Matches Over Time"
              />
            </Suspense>
            <Suspense fallback={<Loading />}>
              <OrganizationInsightsOppInteractionsTimeline
                filters={{ interaction: 'viewed_at', ...values }}
                title="Opportunity Views Over time"
              />
            </Suspense>
            <Suspense fallback={<Loading />}>
              <OrganizationInsightsOppInteractionsTimeline
                filters={{ interaction: 'shared_at', ...values }}
                title="Opportunity Shares Over Time"
              />
            </Suspense>
            <Suspense fallback={<Loading />}>
              <OrganizationInsightsSharesTable filters={{ interaction: 'shared_at', ...values }} />
            </Suspense>
          </section>
        </div>
      )}
    </Formik>
  );
};
