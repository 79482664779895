import React, { useState } from 'react';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { formatTime, DATE_MONTH_AND_DAY, TIME_HOUR_AND_MINUTE } from 'app/lib/dates';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { CommentShow } from 'types/__generated__/GovlyApi';
import { Reactions } from 'app/molecules/Reactions/Reactions';
import { ReactionsStoreProvider } from 'app/molecules/Reactions/useReactionsStore';
import { ReactionsButton } from 'app/molecules/Reactions/ReactionsButton';
import { cn } from 'app/lib/cn';

type CommentProps = React.PropsWithChildren<{
  comment: Pick<CommentShow, 'id' | 'createdAt' | 'content' | 'organizationUser'>;
  editor: React.ReactElement;
  onRemove: () => void;
  isAuthor?: boolean;
}>;

const DELETED_ORGANIZATION_USER = {
  avatarColor: '#293742',
  name: 'User Removed',
  email: '[user removed]',
  initials: '?',
  avatar: null,
  organizationTeams: []
};

export const Comment = ({ comment, editor, onRemove, isAuthor, children }: CommentProps) => {
  const [isEditing, setEditing] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const { id: commentId, organizationUser } = comment;
  const { avatarColor, avatar, name, email, initials, organizationTeams } =
    organizationUser ?? DELETED_ORGANIZATION_USER;

  return (
    <Card className={cn('relative', deleted ? 'hidden' : '')}>
      <ReactionsStoreProvider commentId={comment.id}>
        <CardBody data-test="comment">
          <div className="flex justify-between">
            <AvatarWithName
              initials={initials}
              imgSrc={avatar?.thumbUrl}
              avatarColor={avatarColor}
              id={commentId}
              name={name}
              email={email}
              teams={organizationTeams}
              subtext={`${formatTime(comment.createdAt, DATE_MONTH_AND_DAY)} at ${formatTime(
                comment.createdAt,
                TIME_HOUR_AND_MINUTE
              )}`}
            />

            <div className="flex items-center gap-x-1">
              <ReactionsButton />
              {isAuthor && (
                <Popover
                  content={
                    <Menu key="menu" large>
                      <MenuItem
                        icon="edit"
                        text="Edit"
                        onClick={() => setEditing(true)}
                        data-test="edit-comment-button"
                      />
                      <MenuItem
                        icon="trash"
                        text="Delete"
                        onClick={() => {
                          setDeleted(true);
                          onRemove();
                        }}
                        data-test="delete-comment-button"
                      />
                    </Menu>
                  }
                >
                  <Button minimal icon="more" data-test="comment-menu-button" />
                </Popover>
              )}
            </div>
          </div>
          <div className="space-y-2">
            {isEditing ? (
              React.cloneElement(editor, { afterSubmit: () => setEditing(false) })
            ) : (
              <div className="prose prose-sm">
                <div dangerouslySetInnerHTML={{ __html: comment.content ?? '' }} />
              </div>
            )}
            {children}

            <Reactions />
          </div>
        </CardBody>
      </ReactionsStoreProvider>
    </Card>
  );
};
