import Appsignal from '@appsignal/javascript';
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network';

/* eslint-disable no-console */
if (process.env.NODE_ENV === 'test') {
  const info = console.info;
  console.info = (...msg: Parameters<typeof info>) => {
    if (!msg[0].startsWith('[APPSIGNAL]')) {
      info(...msg);
    }
  };
}
/* eslint-enable no-console */

const appsignal = new Appsignal({
  key: process.env.APP_SIGNAL_KEY,
  revision: process.env.APP_REVISION,
  ignoreErrors: [/ResizeObserver/]
});

appsignal.use(pluginPathDecorator());
appsignal.use(pluginBreadcrumbsNetwork());
appsignal.use(pluginWindowEvents());

export { appsignal };
