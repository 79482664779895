import lodashGet from 'lodash-es/get';
import { OrganizationUserCurrentUser, OrganizationUserApiSettings } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['CurrentUser'] });

type GetCurrentUser = {
  params: void;
  result: OrganizationUserCurrentUser;
};

type GetCurrentUserApiSettings = {
  params: void;
  result: OrganizationUserApiSettings;
};

type PingCurrentUser = {
  params: void;
  result: { id?: string } | undefined;
};

export const currentUserApi = api.injectEndpoints({
  endpoints: build => ({
    getCurrentUser: build.query<GetCurrentUser['result'], GetCurrentUser['params']>({
      query: () => ({ url: '/v2/current_user' }),
      providesTags: ['CurrentUser']
    }),
    getCurrentUserApiSettings: build.query<GetCurrentUserApiSettings['result'], GetCurrentUserApiSettings['params']>({
      query: () => ({ url: '/v2/current_user', params: { view: 'api_settings' } }),
      providesTags: ['CurrentUser']
    }),
    pingCurrentUser: build.query<PingCurrentUser['result'], PingCurrentUser['params']>({
      query: () => '/v2/current_user/ping'
    })
  })
});

export const { useGetCurrentUserQuery, useGetCurrentUserApiSettingsQuery, usePingCurrentUserQuery } = currentUserApi;

export const useCurrentUserAttribute = (attribute: string) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  return lodashGet(currentUser, attribute);
};
