import { AppLayoutProvider } from 'app/organisms/AppLayout/AppLayoutContext';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { match } from 'ts-pattern';

export const AppLayout = ({ children }: React.PropsWithChildren) => {
  let sideNav = null;
  let topNav = null;
  let mobileNav = null;
  let body = null;
  const rest: React.ReactNode[] = [];

  // Use the pathname as a key on the body so scroll resets on nav change
  const { pathname } = useLocation();

  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) return;
    match(child)
      .with({ type: AppSideNav }, el => (sideNav = el))
      .with({ type: AppTopNav }, el => (topNav = el))
      .with({ type: AppBody }, el => (body = el))
      .with({ type: AppMobileNav }, el => (mobileNav = el))
      .otherwise(() => rest.push(child));
  });

  const appBodyRef = React.useRef<HTMLDivElement>(null);
  const topNavRef = React.useRef<HTMLDivElement>(null);

  return (
    <AppLayoutProvider appBodyRef={appBodyRef} topNavRef={topNavRef}>
      <div className="flex flex-nowrap h-screen">
        <div className="hidden lg:block">{sideNav}</div>

        <div className="h-screen overflow-hidden flex-1">
          <div className="h-screen flex flex-col">
            <div className="hidden lg:block" ref={topNavRef}>
              {topNav}
            </div>
            <div className="lg:hidden">{mobileNav}</div>

            <div className="overflow-y-auto flex-1" key={pathname} ref={appBodyRef}>
              {body}
            </div>
          </div>
        </div>
        {rest}
      </div>
    </AppLayoutProvider>
  );
};

export const AppBody = ({ children }: React.PropsWithChildren) => {
  return <>{children}</>;
};

export const AppTopNav = ({ children }: React.PropsWithChildren) => {
  return <>{children}</>;
};

export const AppSideNav = ({ children }: React.PropsWithChildren) => {
  return <>{children}</>;
};

export const AppMobileNav = ({ children }: React.PropsWithChildren) => {
  return <>{children}</>;
};
