import React from 'react';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { SavedSearchesTable } from 'app/organisms/SavedSearchesTable/SavedSearchesTable';
import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const ContactSavedSearchesPage = () => {
  return (
    <SubscriptionRequiredPageWrapper subscriptions={['seatPaid']}>
      <Main>
        <MainColumn>
          <Breadcrumbs />
        </MainColumn>
        <MainColumn>
          <SavedSearchesTable searchableType="Contact" />
        </MainColumn>
      </Main>
    </SubscriptionRequiredPageWrapper>
  );
};
