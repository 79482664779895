import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Drawer } from '@blueprintjs/core';
import { match } from 'ts-pattern';

import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { OppDrawerTitle, OppDrawerBody, showOppDrawer } from 'app/organisms/OppDrawer/OppDrawer';
import {
  OppWorkspaceDrawerTitle,
  OppWorkspaceDrawerBody,
  showWorkspaceDrawer
} from 'app/organisms/OppWorkspaceDrawer/OppWorkspaceDrawer';
import { ContactDrawerTitle, ContactDrawerBody, showContactDrawer } from 'app/organisms/ContactDrawer/ContactDrawer';
import {
  SearchableFeedsFeedDrawerBody,
  SearchableFeedsFeedDrawerTitle,
  showSearchableFeedsFeedDrawer
} from 'app/organisms/SearchableFeeds/SearchableFeedsFeedDrawer';
import {
  ManagedFeedDrawerBody,
  ManagedFeedDrawerTitle,
  showManagedFeedDrawer
} from 'app/organisms/ManagedFeedDrawer/ManagedFeedDrawer';
import { AwardDrawerBody, AwardDrawerTitle, showAwardDrawer } from 'app/organisms/AwardDrawer/AwardDrawer';
import {
  GovernmentEntityDrawerBody,
  GovernmentEntityDrawerTitle,
  showGovernmentEntityDrawer
} from 'app/organisms/GovernmentEntityDrawer/GovernmentEntityDrawer';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { AppDrawerNav } from 'app/organisms/AppDrawer/AppDrawerNav';
import { cn } from 'app/lib/cn';
import { DRAWER_PARAMS, DrawerType } from 'app/organisms/AppDrawer/constants';

const getDrawer = ({
  drawerType,
  drawerId,
  oppShowNavEnabled
}: {
  drawerType?: DrawerType | null;
  drawerId?: string | null;
  oppShowNavEnabled: boolean;
}) => {
  if (!drawerType || !drawerId) return [];

  return match({ drawerType, drawerId })
    .when(showOppDrawer, () => [oppShowNavEnabled ? undefined : OppDrawerTitle, OppDrawerBody])
    .when(showWorkspaceDrawer, () => [oppShowNavEnabled ? undefined : OppWorkspaceDrawerTitle, OppWorkspaceDrawerBody])
    .when(showContactDrawer, () => [ContactDrawerTitle, ContactDrawerBody])
    .when(showSearchableFeedsFeedDrawer, () => [SearchableFeedsFeedDrawerTitle, SearchableFeedsFeedDrawerBody])
    .when(showManagedFeedDrawer, () => [ManagedFeedDrawerTitle, ManagedFeedDrawerBody])
    .when(showAwardDrawer, () => [AwardDrawerTitle, AwardDrawerBody])
    .when(showGovernmentEntityDrawer, () => [GovernmentEntityDrawerTitle, GovernmentEntityDrawerBody])
    .otherwise(() => []);
};

export const AppDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useDeviceWidth();

  const drawerType = searchParams.get(DRAWER_PARAMS.type);
  const drawerId = searchParams.get(DRAWER_PARAMS.id);
  const drawerSize = searchParams.get(DRAWER_PARAMS.size) ?? '85%';

  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');
  const [Title, Body] = getDrawer({ drawerType: drawerType as DrawerType, drawerId, oppShowNavEnabled });

  if ((!oppShowNavEnabled && !Title) || !Body || !drawerId || !drawerType) {
    return null;
  }

  const clearSearchParams = () => {
    searchParams.delete(DRAWER_PARAMS.type);
    searchParams.delete(DRAWER_PARAMS.id);
    searchParams.delete(DRAWER_PARAMS.size);

    setSearchParams(searchParams);
  };

  return (
    <Drawer
      lazy
      onClose={clearSearchParams}
      isOpen={true}
      position="right"
      title={Title ? <Title id={drawerId} /> : <AppDrawerNav />}
      size={isMobile ? '100%' : drawerSize}
      className={cn('overflow-y-scroll', {
        '[&_.bp5-heading]:overflow-visible': !Title // 🤮
      })}
    >
      <Body id={drawerId} />
    </Drawer>
  );
};
