import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { LlmGeneratedIndicator } from 'app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

export type LineItem = {
  vendor?: string;
  product?: string;
  service?: string;
  quantity?: number;
  description?: string;
  partNumber?: string;
};

export type OppLineItemsTableProps = {
  data?: Array<LineItem>;
  title?: string;
};

const columnHelper = createColumnHelper<LineItem>();

const columns = [
  columnHelper.accessor('vendor', {
    header: 'Vendor',
    sortingFn: 'basic',
    filterFn: 'fuzzyText'
  }),
  columnHelper.accessor('product', {
    header: 'Product/Service',
    sortingFn: 'basic',
    filterFn: 'fuzzyText',
    cell: e => e.row.original.product || e.row.original.service
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
    sortingFn: 'basic'
  }),
  columnHelper.accessor('partNumber', {
    header: 'Part Number',
    sortingFn: 'basic'
  })
];

export const OppLineItemsTable = ({ data, title, ...rest }: OppLineItemsTableProps) => {
  const lineItems = data?.filter((item: LineItem) => !!item.vendor);
  if (!lineItems || lineItems.length === 0) {
    return null;
  }

  return (
    <GovlyTable
      id="opp_line_items_table"
      columns={columns}
      data={lineItems}
      title={<GovlyTableTitle title={<CardHeadingSmall>{title || 'Line Items'}</CardHeadingSmall>} />}
      cardProps={{
        compact: true,
        collapseProps: { defaultIsOpen: false },
        collapsible: true,
        icon: <LlmGeneratedIndicator icon="generate" />
      }}
      {...rest}
    />
  );
};
