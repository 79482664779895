import React from 'react';
import { SegmentedControl } from '@blueprintjs/core';
import { useFormikContext } from 'formik';
import { match } from 'ts-pattern';

import { defaultState, OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { FormikToBp } from 'app/lib/formikToBp';
import { futureDateShortcuts } from 'app/lib/dates';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { InputCompositeFilter } from 'app/molecules/InputCompositeFilter/InputCompositeFilter';
import { FuzzyInputMultiSelect } from 'app/molecules/InputMultiSelect/FuzzyInputMultiSelect';
import { makeSafeFormikFields } from 'app/lib/formik';

const { safeTuples } = makeSafeFormikFields<OppSearchState>();

const DEFAULT_DATE_RANGE = {
  range: defaultState.filters.dateRange,
  param: defaultState.filters.dateRangeParam
};

const namesAndLabels = safeTuples(['filters.recordType', 'Only'], ['filters.recordTypeNone', 'Exclude']);
const items = [
  { label: 'Solicitation', value: 'Solicitation' },
  { label: 'Forecast', value: 'Forecast' },
  { label: 'Prediction', value: 'Prediction' },
  { label: 'Event', value: 'Event' },
  { label: 'Other', value: 'Other' }
];

const META_SEARCH_FORECAST = JSON.stringify(['Forecast', 'Prediction']);
const META_SEARCH_SOLICITATION = JSON.stringify(['Solicitation']);
const META_SEARCH_EVENT = JSON.stringify(['Event']);

export const OppSearchRecordType = () => {
  const getField = useGetFormikField<OppSearchState>();
  const { values, setValues, submitForm } = useFormikContext<OppSearchState>();

  const recordTypeString = JSON.stringify([...getField('filters.recordType').field.value].sort());
  const recordTypeNoneLength = getField('filters.recordTypeNone').field.value.length;

  const metaSearchType = match({ recordTypeString, recordTypeNoneLength })
    .with({ recordTypeString: META_SEARCH_FORECAST, recordTypeNoneLength: 0 }, () => 'forecasts')
    .with({ recordTypeString: META_SEARCH_SOLICITATION, recordTypeNoneLength: 0 }, () => 'solicitations')
    .with({ recordTypeString: META_SEARCH_EVENT, recordTypeNoneLength: 0 }, () => 'events')
    .otherwise(() => 'none');

  return (
    <InputFilterCardListItem title="Entity type" defaultIsOpen className="space-y-2">
      <SegmentedControl
        fill
        small
        intent="primary"
        value={metaSearchType}
        onValueChange={value => {
          let newFilters = {};
          switch (value) {
            case 'solicitations':
              newFilters = {
                recordType: ['Solicitation'],
                dateRange: DEFAULT_DATE_RANGE.range,
                dateRangeParam: DEFAULT_DATE_RANGE.param
              };
              break;
            case 'forecasts':
              newFilters = {
                recordType: ['Forecast', 'Prediction'],
                dateRange: futureDateShortcuts()[7].dateRange,
                dateRangeParam: 'respond_by'
              };
              break;
            case 'events':
              newFilters = {
                recordType: ['Event'],
                dateRange: futureDateShortcuts()[7].dateRange,
                dateRangeParam: 'respond_by'
              };
              break;
          }

          setValues({
            ...values,
            filters: {
              ...defaultState.filters,
              ...newFilters
            }
          });
          submitForm();
        }}
        options={[
          { value: 'solicitations', label: 'Solicitations', disabled: false },
          { value: 'forecasts', label: 'Forecasts', disabled: false },
          { value: 'events', label: 'Events', disabled: false }
        ]}
      />

      <InputCompositeFilter
        noPrimaryInput={true}
        advancedShowLabel={'Customize'}
        advancedHideLabel={'Hide custom options'}
      >
        {namesAndLabels.map(([name, label]) => {
          return (
            <FuzzyInputMultiSelect
              key={name}
              getItemTextLabel={item => item.label}
              getItemValue={item => item.value}
              useTypeaheadProps={{ options: { keys: ['label'] } }}
              items={items}
              placeholder={label}
              {...FormikToBp.toMultiSelect({ ...getField(name), items, getItemValue: item => item.value })}
              onChange={items => {
                getField(name).helpers.setValue(items.map(item => item.value));
                submitForm();
              }}
            />
          );
        })}
      </InputCompositeFilter>
    </InputFilterCardListItem>
  );
};
