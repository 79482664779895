import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Button, Dialog, AnchorButton, Menu, MenuItem, Popover } from '@blueprintjs/core';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useGetOppSearchesQuery, useUpdateOppSearchMutation } from 'api/oppSearchesApi';
import { useCreateSavedSearchFollowMutation, useDeleteSavedSearchFollowMutation } from 'api/savedSearchFollowsApi';
import { errorToast, successToast } from 'app/lib/toaster';
import { OppSavedSearchCreateForm } from 'app/organisms/OppSavedSearchCreateForm/OppSavedSearchCreateForm';
import { OppSavedSearchUpdateForm } from 'app/organisms/OppSavedSearchUpdateForm/OppSavedSearchUpdateForm';
import { defaultState, OppSearchState } from 'app/hooks/search/useOppSearchCache';

export const OppSavedSearchControls = () => {
  const navigate = useNavigate();
  const {
    setValues,
    values: { query, filters, meta }
  } = useFormikContext<OppSearchState>();
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [templateSearchId, setTemplateSearchId] = useState<string>();

  const selected = meta.savedSearchId;

  const currentOrgUserId = useCurrentUserAttribute('id');
  const [createOppSearchFollow] = useCreateSavedSearchFollowMutation();
  const [deleteOppSearchFollow] = useDeleteSavedSearchFollowMutation();
  const [updateSavedSearch] = useUpdateOppSearchMutation();

  const { selectedSavedSearch, isLoading } = useGetOppSearchesQuery(
    { active: true },
    {
      selectFromResult: ({ data, isLoading }) => ({
        selectedSavedSearch: data?.find(({ id }) => id === selected),
        isLoading
      })
    }
  );

  const toggleIsCreating = () => setIsCreating(!isCreating);
  const toggleIsEditing = () => setIsEditing(!isEditing);

  return (
    <>
      {selected && selectedSavedSearch && (
        <>
          <Popover
            interactionKind="click"
            className="max-w-md"
            content={
              <Menu key="menu" large>
                {selectedSavedSearch.currentUserFollowing ? (
                  <MenuItem
                    icon="thumbs-down"
                    text="Unfollow"
                    onClick={async () => {
                      try {
                        await deleteOppSearchFollow({
                          organizationUserId: currentOrgUserId,
                          savedSearchId: selected,
                          savedSearchType: 'OppSearch'
                        }).unwrap();
                        successToast();
                      } catch (e) {
                        errorToast(e);
                      }
                    }}
                  />
                ) : (
                  <MenuItem
                    icon="thumbs-up"
                    text="Follow"
                    onClick={async () => {
                      try {
                        await createOppSearchFollow({
                          organizationUserId: currentOrgUserId,
                          savedSearchId: selected,
                          savedSearchType: 'OppSearch'
                        }).unwrap();

                        successToast();
                      } catch (e) {
                        errorToast(e);
                      }
                    }}
                  />
                )}
                <MenuItem icon="add" text="Create new" onClick={toggleIsCreating} />
                <MenuItem
                  icon="duplicate"
                  text="Duplicate"
                  onClick={() => {
                    setTemplateSearchId(selected);
                    toggleIsCreating();
                  }}
                />
                {selectedSavedSearch.currentUserFollowing && (
                  <MenuItem
                    icon="archive"
                    text="Archive"
                    onClick={async () => {
                      try {
                        await updateSavedSearch({
                          id: selected,
                          active: false
                        }).unwrap();

                        setValues(defaultState);
                        successToast();
                      } catch (e) {
                        errorToast(e);
                      }
                    }}
                  />
                )}
                <MenuItem
                  icon="align-justify"
                  text="View all saved searches"
                  onClick={() => {
                    navigate('/opportunities/saved_searches', {
                      state: {
                        breadcrumbs: [{ to: '/opportunities', text: 'Opportunities' }, { text: 'Saved Searches' }]
                      }
                    });
                  }}
                />
              </Menu>
            }
          >
            <AnchorButton icon="more" large disabled={isLoading} />
          </Popover>
          <Button large disabled={isLoading} onClick={toggleIsEditing}>
            Update
          </Button>
        </>
      )}
      {!selected && (
        <>
          <Popover
            interactionKind="click"
            className="max-w-md"
            content={
              <Menu key="menu" large>
                <MenuItem
                  icon="align-justify"
                  text="View all saved searches"
                  onClick={() => {
                    navigate('/opportunities/saved_searches', {
                      state: {
                        breadcrumbs: [{ to: '/opportunities', text: 'Opportunities' }, { text: 'Saved Searches' }]
                      }
                    });
                  }}
                />
              </Menu>
            }
          >
            <AnchorButton icon="more" large disabled={isLoading} />
          </Popover>
          <Button large onClick={toggleIsCreating} data-test="save-new-search-button">
            Save
          </Button>
        </>
      )}
      <Dialog
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
        className="min-w-[50%] bg-white p-0"
        title="Create a new saved search"
      >
        <OppSavedSearchCreateForm
          templateSavedSearchId={templateSearchId}
          onCancel={toggleIsCreating}
          handleClose={toggleIsCreating}
        />
      </Dialog>
      {selected && (
        <Dialog
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          className="min-w-[50%] bg-white p-0"
          title="Update saved search"
        >
          <OppSavedSearchUpdateForm
            savedSearchId={selected}
            onCancel={toggleIsEditing}
            handleClose={toggleIsEditing}
            initialQuery={query}
            initialFilters={filters}
          />
        </Dialog>
      )}
    </>
  );
};
