import React, { useState } from 'react';
import { Alert, Button, Icon, Tag } from '@blueprintjs/core';
import { AttachmentEntityTitle } from 'app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { AttachmentEntityTitleUtils } from 'app/organisms/OppWorkspaceAttachmentsCard/utils';
import { WorkspaceAttachmentToolsButton } from 'app/organisms/WorkspaceAttachmentToolsButton/WorkspaceAttachmentToolsButton';
import { FileUploader } from 'app/molecules/FileUploaders/FileUploader/FileUploader';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { cn } from 'app/lib/cn';
import {
  useCreateWorkspaceAttachmentsMutation,
  useDeleteWorkspaceAttachmentMutation,
  useGetWorkspaceAttachmentsQuery
} from 'api/workspacesAttachmentsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { CardListItem, CardListItemProps } from 'app/molecules/CardList/CardList';

export const OppWorkspaceAttachmentsCard = ({
  workspaceId,
  cardListItemProps
}: {
  workspaceId: string;
  cardListItemProps: CardListItemProps;
}) => {
  const { data: attachments = [], isLoading } = useGetWorkspaceAttachmentsQuery({ id: workspaceId });

  const [formState, setFormState] = useState<'idle' | 'uploading'>('idle');
  const { data: currentUser } = useGetCurrentUserQuery();
  const [createAttachments, createAttachmentsMeta] = useCreateWorkspaceAttachmentsMutation();
  const [deleteWorkspaceAttachment, deleteWorkspaceAttachmentMeta] = useDeleteWorkspaceAttachmentMutation();
  const [confirmDeleteAttachmentId, setConfirmDeleteAttachmentId] = useState<string | null>(null);

  const workspaceAttachments = attachments.slice().sort((a, b) => b.createdAt?.localeCompare(a.createdAt ?? '') ?? 0);

  const isUploadingDisabled = formState === 'uploading' || createAttachmentsMeta.isLoading;

  return (
    <CardListItem
      {...cardListItemProps}
      title={
        <div className="flex items-center gap-1">
          <span>Attachments</span>
          <Tag minimal>{workspaceAttachments.length}</Tag>
        </div>
      }
    >
      {isLoading ? (
        <Loading type="card-body" />
      ) : (
        <dl className="flex flex-col gap-y-4 col-span-2">
          {workspaceAttachments.map(attachment => (
            <AttachmentEntityTitle
              key={attachment.id}
              tooltipProps={{ className: 'max-w-full' }}
              {...AttachmentEntityTitleUtils.fromWorkspaceAttachment(attachment)}
              actionsButtonGroup={
                <>
                  <Button
                    aria-label="Delete attachment"
                    icon={<Icon icon="trash" size={12} />}
                    intent="danger"
                    loading={
                      deleteWorkspaceAttachmentMeta.isLoading &&
                      deleteWorkspaceAttachmentMeta.originalArgs?.attachmentId === attachment.id
                    }
                    small
                    onClick={() => {
                      setConfirmDeleteAttachmentId(attachment.id);
                    }}
                  />
                  <WorkspaceAttachmentToolsButton attachment={attachment} />
                </>
              }
            />
          ))}

          {currentUser?.compliancePreventUploads ? undefined : (
            <FileUploader
              id={workspaceId}
              multiple
              onInitialize={() => setFormState('uploading')}
              disabled={isUploadingDisabled}
              onBulkAttach={async attachments => {
                await createAttachments({ id: workspaceId, attachments: attachments.map(a => a.signedId) });
                setFormState('idle');
              }}
            >
              {({ isDragActive }) => (
                <Button
                  outlined
                  icon="paperclip"
                  disabled={isUploadingDisabled}
                  loading={isUploadingDisabled}
                  className={cn(isDragActive ? 'outline-dashed outline-blue-500' : '')}
                  fill
                >
                  Add Attachments
                </Button>
              )}
            </FileUploader>
          )}

          <Alert
            isOpen={confirmDeleteAttachmentId !== null}
            intent="danger"
            onClose={() => setConfirmDeleteAttachmentId(null)}
            confirmButtonText="Delete Attachment"
            cancelButtonText="Cancel"
            canEscapeKeyCancel
            canOutsideClickCancel
            onConfirm={() => {
              deleteWorkspaceAttachment({ workspaceId, attachmentId: confirmDeleteAttachmentId ?? '' });
              setConfirmDeleteAttachmentId(null);
            }}
          >
            <p>Are you sure? The attachment will be deleted permanently.</p>
          </Alert>
        </dl>
      )}
    </CardListItem>
  );
};
