import React from 'react';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';

export const AppLoading = () => {
  return (
    <>
      <Main>
        <MainColumn>
          <Loading />
          <div className="hidden lg:block">
            <Loading />
          </div>
        </MainColumn>
      </Main>
    </>
  );
};
