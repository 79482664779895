import React from 'react';
import { Card, CardBody } from 'app/atoms/Card/Card';

import { useGetAwardQuery } from 'api/awardsApi';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { formatAddress } from 'app/lib/address';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Tree } from '@blueprintjs/core';
import { getAwardStatus } from 'app/organisms/AwardDetails/utils';
import { AwardDetailsTransactionsTable } from 'app/organisms/AwardDetails/AwardDetailsTransactionsTable';
import { AwardDrawerTitle } from 'app/organisms/AwardDrawer/AwardDrawer';
import { useSearchParams } from 'react-router-dom';
import { AwardDetailsAmountChart } from 'app/organisms/AwardDetails/AwardDetailsAmountChart';
import { AwardDetailsContractActivityGraph } from 'app/organisms/AwardDetails/AwardDetailsContractActivityGraph';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';

export const AwardDetails = ({ id }: { id: string }) => {
  const { data: award, isLoading } = useGetAwardQuery({ id });
  const inDrawer = useSearchParams()[0].get(DRAWER_PARAMS.id);

  if (isLoading) return <AppLoading />;
  if (!award) return null;

  const recipientAddress = formatAddress({
    street: award.recipientStreet,
    unit: award.recipientUnit,
    city: award.recipientCityName,
    state: award.recipientStateName,
    zip: award.recipientZipCode,
    country: award.recipientCountryName
  });

  const { status, statusDetail } = getAwardStatus({ award });

  return (
    <>
      <Card
        title={[status, statusDetail].filter(Boolean).join(' ')}
        subtitle={inDrawer ? undefined : <AwardDrawerTitle id={id} />}
      >
        <CardBody>
          <div className="flex flex-wrap gap-2">
            <DescriptionListField
              className="items-start flex-1"
              label={<span className="flex items-center gap-1">Awarding Agency {AWARD_VS_FUNDING_TOOLTIP}</span>}
              value={
                <Tree
                  className="[&>*>*>.bp5-tree-node-content-0]:font-medium [&>*>*>.bp5-tree-node-content-0]:text-lg"
                  contents={[
                    {
                      id: 0,
                      label: award.awardingAgencyName ?? '',
                      isExpanded: Boolean(award.fundingSubAgencyName),
                      childNodes: [{ id: 1, label: award.awardingSubAgencyName ?? '' }]
                    }
                  ]}
                />
              }
            />
            <DescriptionListField
              label="Recipient"
              className="items-start flex-1"
              value={<span className="font-medium text-lg">{award.recipientName}</span>}
              cols={1}
            />
          </div>
          <DescriptionListField label="Description" value={award.primeAwardBaseTransactionDescription} cols={2} />
        </CardBody>
      </Card>

      <ErrorBoundary action="AwardDetailsAmountChart" fallback={<CardError title="Award Amounts" />}>
        <Card className="px-4">
          <AwardDetailsAmountChart award={award} />
        </Card>
      </ErrorBoundary>

      {award.awardOrIdvFlag === 'AWARD' && (
        <ErrorBoundary action="AwardDetailsContractActivityGraph" fallback={<CardError title="Contract Activity" />}>
          <Card className="px-4">
            <AwardDetailsContractActivityGraph award={award} />
          </Card>
        </ErrorBoundary>
      )}

      {award.awardOrIdvFlag === 'AWARD' && <AwardDetailsTransactionsTable award={award} />}

      <Card title={'Agency Details'}>
        <CardBody>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <DescriptionListField
              label={
                <span className="flex items-center gap-1">
                  Awarding Agency
                  {AWARD_VS_FUNDING_TOOLTIP}
                </span>
              }
              value={award.awardingAgencyName}
              cols={1}
            />
            <DescriptionListField
              label={
                <span className="flex items-center gap-1">
                  Funding Agency
                  {AWARD_VS_FUNDING_TOOLTIP}
                </span>
              }
              value={award.fundingAgencyName}
              cols={1}
            />
            <DescriptionListField label="Awarding Sub-Agency" value={award.awardingSubAgencyName} cols={1} />
            <DescriptionListField label="Funding Sub-Agency" value={award.fundingSubAgencyName} cols={1} />
            <DescriptionListField label="Awarding Office" value={award.awardingOfficeName} cols={1} />
            <DescriptionListField label="Funding Office" value={award.fundingOfficeName} cols={1} />
          </dl>
        </CardBody>
      </Card>

      <Card title={'Recipient Details'}>
        <CardBody>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <DescriptionListField label="Recipient" value={award.recipientName} cols={1} />
            <DescriptionListField label="Parent Recipient" value={award.recipientParentName} cols={1} />
            <DescriptionListField label="Recipient Address" value={recipientAddress.join(', ')} cols={1} />
            <DescriptionListField
              label="Business Types"
              value={award.searchTags?.filter(tag => !IGNORED_SEARCH_TAGS.has(tag)).join(', ')}
              cols={1}
            />
            <DescriptionListField
              label="Congressional District"
              value={
                award.primeAwardTransactionPlaceOfPerformanceCdCurrent &&
                award.recipientStateCode &&
                `${award.primeAwardTransactionPlaceOfPerformanceCdCurrent}-${award.recipientStateCode}`
              }
              cols={1}
            />
          </dl>
        </CardBody>
      </Card>
    </>
  );
};

const IGNORED_SEARCH_TAGS = new Set(['NOT BUNDLED']);

const AWARD_VS_FUNDING_TOOLTIP = (
  <InfoTooltip>
    The <b>awarding agency</b> for an award is the agency that creates and administers the award, while the{' '}
    <b>funding agency</b> pays for the award. In most cases, the awarding and funding agency are the same. The division
    of funding and awarding agencies benefits many smaller agencies, who may not have the staff available to administer
    an award.
  </InfoTooltip>
);
