import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { useGetOppQuery } from 'api/oppsApi';
import { useCurrentUserAttribute } from 'api/currentUserApi';

interface OppDetailsProvidersProps {
  id: string;
}

type Organization = {
  name?: string;
  id?: string;
  peerId?: string;
};

const columnHelper = createColumnHelper<Organization>();

export const OppDetailsProviders: React.FC<OppDetailsProvidersProps> = ({ id, ...rest }) => {
  const organizationName = useCurrentUserAttribute('organizationName') as string;
  const { data: opp, isLoading } = useGetOppQuery({ id });

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        enableColumnFilter: true,
        cell: e => {
          if (!e.row.original.id) {
            return <span className="text-lg font-medium text-gray-500">{e.row.original.name}</span>;
          }

          return (
            <LinkTag to={`/partnerships/${e.row.original.peerId}`} className="text-lg font-medium">
              {e.row.original.name}
            </LinkTag>
          );
        }
      })
    ],
    []
  );

  const data = useMemo(() => {
    const providerPartnerships = opp?.providerPartnerships ?? [];

    const transformedData: Organization[] = providerPartnerships
      .filter(partnership => !!partnership.organization?.name)
      .map(partnership => ({
        name: partnership?.organization?.name,
        id: partnership?.organization?.id,
        peerId: partnership.peerId
      }));

    if (opp?.isProvider) {
      transformedData.unshift({ name: organizationName });
    }

    return transformedData;
  }, [opp, organizationName]);

  if (opp?.contractVehicle?.public || (!opp?.providerPartnerships?.length && !opp?.isProvider)) {
    return null;
  }

  return (
    <div {...rest}>
      <GovlyTable
        id="opp_details_providers"
        columns={columns}
        isLoading={isLoading}
        data={data}
        title="Providers"
        initialState={{ pagination: { pageSize: 5 } }}
      />
    </div>
  );
};
