import React, { memo, useMemo, useState } from 'react';
import { Tag, AnchorButton, Menu, MenuItem, Dialog, Button, Popover, Tooltip } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';
import truncate from 'lodash-es/truncate';

import { useOppSearchResult, useOppFromSearchDecorator } from 'api/oppsApi';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchResultTags } from 'app/organisms/OppSearchResultTags/OppSearchResultTags';
import { OppSearchEntityExtractTagList } from 'app/organisms/OppSearchEntityExtractTagList/OppSearchEntityExtractTagList';
import { OppSearchIgnoreButton } from 'app/organisms/OppSearchIgnoreButton/OppSearchIgnoreButton';
import { OppSearchFollowButton } from 'app/organisms/OppSearchFollowButton/OppSearchFollowButton';
import { OppSearchCrmButton } from 'app/organisms/OppSearchCrmButton/OppSearchCrmButton';
import { OppSearchWorkspaces } from 'app/organisms/OppSearchWorkspaces/OppSearchWorkspaces';
import { OppExpirationStatus } from 'app/organisms/_Opp/OppExpirationStatus';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { ShareViaEmailForm } from 'app/organisms/ShareViaEmailForm/ShareViaEmailForm';
import { useShareOppMutation } from 'api/oppsApi';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { ProviderLogos } from 'app/organisms/ProviderLogos/ProviderLogos';
import { OppSearchResultBuyer } from 'app/organisms/OppSearchResultBuyer/OppSearchResultBuyer';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { SetAsideTag } from 'app/organisms/SetAsideTag/SetAsideTag';
import { LabelList } from 'app/organisms/LabelList/LabelList';
import { LabelFormDialog } from 'app/organisms/LabelFormDialog/LabelFormDialog';
import { presence } from 'app/lib/arrays';
import { OppSearchResultHighlight } from './OppSearchResultHighlight';

type Action = Partial<{ isSharing: boolean; isUpdatingWorkspace: boolean; createWorkspace: boolean }>;

export const OppSearchResult = memo(({ id }: { id: string }) => {
  const { isMobile } = useDeviceWidth();
  const [action, setAction] = useState<Action>({});
  const [addingLabel, setAddingLabel] = useState(false);
  const [_searchParams, setSearchParams] = useSearchParams();
  const [shareOpp, { isLoading: isSubmittingShare }] = useShareOppMutation();

  const { oppSearchResult: esOpp } = useOppSearchResult(id);
  const { opp, isLoading: fullOppLoading } = useOppFromSearchDecorator(id);
  // the opp is more granularly scoped by permissions
  const respondBy = opp?.respondBy !== esOpp?.respondBy ? opp?.respondBy : esOpp?.respondBy;

  const { data: savedSearches = [] } = useGetOppSearchesQuery({ active: true });

  const matchedSearches = useMemo(() => {
    return savedSearches
      .filter(
        ({ id: searchId, currentUserFollowing }) =>
          esOpp?.matchedOppSearchIds?.includes(searchId) && currentUserFollowing
      )
      .map(({ name }) => ({ name, id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esOpp?.matchedOppSearchIds, savedSearches]);

  const openOpp = (event: React.MouseEvent<HTMLElement>) => {
    openDrawerOrNewTab(event, `/opportunities/${id}`, () =>
      setSearchParams({ drawerType: 'opportunities', drawerId: id })
    );
  };

  const sharedTo = opp?.oppInteraction?.sharedTo?.length ? opp?.oppInteraction?.sharedTo : [];
  const matchedSearchNames = matchedSearches.map(({ name }) => name);

  if (!esOpp) return null;

  return (
    <>
      <Card>
        <CardSection className="flex flex-col space-y-4">
          <div className="flex flex-wrap items-start justify-between gap-x-2 gap-y-4">
            <div className="flex items-center gap-x-2 gap-y-2">
              <LinkTag tag="a" onClick={openOpp}>
                <NewTabTooltip title="View Opportunity">
                  <Tag interactive>{esOpp.displayName}</Tag>
                </NewTabTooltip>
              </LinkTag>
              {esOpp.recordType && <Tag minimal>{esOpp.recordType}</Tag>}
            </div>
            <div className="flex flex-wrap items-start gap-x-2 gap-y-2">
              <OppSearchIgnoreButton id={id} interaction={opp?.oppInteraction} isLoading={!opp} />
              <OppSearchFollowButton id={id} interaction={opp?.oppInteraction} isLoading={!opp} />
              <OppSearchCrmButton id={id} interaction={opp?.crmInteraction} isLoading={!opp} />
              <Tooltip content={sharedTo.length ? `Previously shared to: ${sharedTo.join(', ')}` : undefined}>
                <Button
                  intent={sharedTo.length ? 'primary' : 'none'}
                  icon="send-message"
                  text={isMobile ? null : 'Share'}
                  onClick={() => setAction({ isSharing: true })}
                />
              </Tooltip>
              <Popover
                interactionKind="click"
                className="max-w-md"
                content={
                  <Menu key="menu" large>
                    <MenuItem
                      icon="people"
                      text="Add/Remove Followers (Default Workspace)"
                      onClick={() => setAction({ isUpdatingWorkspace: true })}
                    />
                    <MenuItem
                      icon="plus"
                      text="Create a new Workspace"
                      onClick={() => setAction({ isUpdatingWorkspace: true, createWorkspace: true })}
                    />
                    <MenuItem icon="tag" text="Add a label" onClick={() => setAddingLabel(true)} />
                  </Menu>
                }
              >
                <AnchorButton icon="more" />
              </Popover>
            </div>
          </div>
          <LinkTag tag="a" onClick={openOpp} className="font-medium no-underline" data-test="opp-search-result">
            <NewTabTooltip title="View Opportunity">
              <h3 className="inline-block">{truncate(esOpp.title, { length: 200 })}</h3>
            </NewTabTooltip>
          </LinkTag>

          <OppSearchResultHighlight highlights={esOpp.highlight} />
          <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
            {esOpp.recordType?.includes('Prediction') ? (
              <Tag minimal>{`Predicted: ${formatTime(esOpp.postedAt ?? '', DATETIME_24_SHORT)}`}</Tag>
            ) : (
              <>
                <OppExpirationStatus
                  respondBy={respondBy}
                  awardedAt={esOpp.awardedAt}
                  cancelledAt={esOpp.cancelledAt}
                />
                {esOpp.postedAt && <Tag minimal>{`Received: ${formatTime(esOpp.postedAt, DATETIME_24_SHORT)}`}</Tag>}
                {opp?.modified && opp?.modifiedAt && (
                  <Tag minimal>{`Modified: ${formatTime(opp.modifiedAt, DATETIME_24_SHORT)}`}</Tag>
                )}
              </>
            )}
            <SetAsideTag code={esOpp.setAside} />

            {esOpp.primaryNoticeType && (
              <Tag minimal icon="document">
                {esOpp.primaryNoticeType}
              </Tag>
            )}
            {esOpp.region && (
              <Tag minimal icon="map-marker" intent="primary">
                {esOpp.region}
              </Tag>
            )}
            <OppSearchResultBuyer buyerName={esOpp.buyerName ?? ''} buyerHierarchy={esOpp.buyerHierarchy} />
          </div>
          {presence(matchedSearches) && (
            <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
              <Tag intent="warning">{`Matched searches: ${matchedSearchNames?.join(', ')}`}</Tag>
            </div>
          )}

          {(presence(opp?.labels) || presence(opp?.taggings)) && (
            <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
              <LabelList labels={opp?.labels ?? []} />
              <OppSearchResultTags tags={opp?.taggings} />
            </div>
          )}
          {presence(esOpp.vendors) && (
            <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
              <OppSearchEntityExtractTagList entities={esOpp.vendors ?? []} pluralizedLabels={['vendor', 'vendors']} />
            </div>
          )}
          {(esOpp.providerFeedIds?.length ?? 0) > 0 && (
            <div>
              <ProviderLogos
                providerFeedIds={esOpp.providerFeedIds ?? []}
                className="flex gap-x-2"
                style={{ height: '30px' }}
              />
            </div>
          )}

          {fullOppLoading ? (
            <Loading type="flex-row" />
          ) : (
            <OppSearchWorkspaces opp={opp} action={action} setAction={setAction} />
          )}

          <Dialog isOpen={action.isSharing} className="min-w-[50%] p-0">
            <ShareViaEmailForm
              title="Share Opportunity"
              onCancel={() => setAction({})}
              initialValues={{
                subject: `${esOpp.displayName}: ${esOpp.title}`,
                id
              }}
              isSubmitting={isSubmittingShare}
              onSubmit={values => shareOpp(values).unwrap()}
            />
          </Dialog>
        </CardSection>
      </Card>
      <LabelFormDialog
        isOpen={addingLabel}
        onClose={() => setAddingLabel(false)}
        initialValues={{
          organizationLabelIds: opp?.labels?.map(({ id }) => id) || [],
          taggableId: id,
          taggableType: 'Opp'
        }}
      />
    </>
  );
});

OppSearchResult.displayName = 'OppSearchResult';
