import React from 'react';
import { useGetAwardQuery } from 'api/awardsApi';
import { CardList, CardListItem, CardListItemProps } from 'app/molecules/CardList/CardList';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { asCurrency, asPercentage } from 'app/lib/numbers';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { formatAddress } from 'app/lib/address';
import { AnchorButton } from '@blueprintjs/core';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';
import { CopyButton } from 'app/molecules/CopyButton/CopyButton';

export const AwardDetailsAdditional = ({ id }: { id: string }) => {
  const { data: award, isLoading } = useGetAwardQuery({ id });
  const isIDV = award?.awardOrIdvFlag === 'IDV';

  if (isLoading || !award) return null;

  const popAddress = formatAddress({
    city: award.primaryPlaceOfPerformanceCityName,
    state: award.primaryPlaceOfPerformanceStateName,
    zip: award.primaryPlaceOfPerformanceZip,
    country: award.primaryPlaceOfPerformanceCountryName
  });

  const [total, potential, obligated, outlayed] = [
    award.currentTotalValueOfAward,
    award.potentialTotalValueOfAward,
    award.totalDollarsObligated,
    award.totalOutlayedAmountForOverallAward
  ].map(val => (val ? parseFloat(val) : 0));

  return (
    <CardList>
      <CardListItem title="Additional Award Details" {...cardListItemProps}>
        <DescriptionListField
          label="Public Link"
          value={
            award.usaspendingPermalink && (
              <AnchorButton
                small
                minimal
                className="text-blue-500 -ml-2"
                rightIcon="open-application"
                href={award.usaspendingPermalink}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                View on USASpending.gov
              </AnchorButton>
            )
          }
        />

        <DescriptionListField
          innerClassName="w-full"
          label="Unique Key"
          value={
            award.contractAwardUniqueKey ? (
              <span className="flex gap-px items-center">
                <span className="truncate">{award.contractAwardUniqueKey}</span>
                <CopyButton copyText={award.contractAwardUniqueKey} minimal small />
              </span>
            ) : null
          }
        />

        <DescriptionListField
          label={
            <span className="flex items-center gap-1">
              Parent Award Unique Key
              <InfoTooltip>
                <p>
                  A parent award, or parent indefinite delivery vehicle (IDV), is any award that has other prime awards
                  made under it.
                </p>

                <p>The contract summarized on this page is a “child” prime award of the parent IDV indicated here.</p>

                <p>
                  Click on the parent award ID to view the summary page of this award&apos;s parent award, which details
                  all of that parent award&apos;s “child” and “grandchild” awards.
                </p>
              </InfoTooltip>
            </span>
          }
          value={
            award.parentAwardUniqueKey ? (
              <span className="flex gap-px items-center">
                <AnchorButton
                  small
                  minimal
                  className="text-blue-500 -ml-2"
                  rightIcon="open-application"
                  href={`/awards/${award.parentAwardUniqueKey}`}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <span className="truncate">{award.parentAwardUniqueKey}</span>
                </AnchorButton>
              </span>
            ) : null
          }
        />

        <DescriptionListField
          label="Child Awards"
          value={
            isIDV && (
              <AnchorButton
                small
                minimal
                className="text-blue-500 -ml-2"
                rightIcon="open-application"
                href={`/awards?query=${award.contractAwardUniqueKey}`}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                View awards under this IDV
              </AnchorButton>
            )
          }
        />

        <DescriptionListField label="Parent Award PIID" value={award.parentAwardIdPiid} />
        <DescriptionListField label="Place of Performance" value={popAddress.join(', ')} />
        <DescriptionListField label="Procurement Instrument Identifier (PIID)" value={award.awardIdPiid} />
        <DescriptionListField label="NAICS Code" value={award.naicsCode} />
        <DescriptionListField label="PSC Code" value={award.productOrServiceCode} />
        <DescriptionListField label="Award or IDV" value={award.awardOrIdvFlag} />
      </CardListItem>

      <CardListItem
        title={
          <span className="flex items-center gap-1">
            Award Amounts
            <InfoTooltip>
              <p>
                <b>Outlayed Amount</b> (or Spent Amount) represents the actual funds that have been disbursed or spent.
                It indicates the financial resources that have been paid out to the recipient as they carry out the work
                or services described in the award or contract.
              </p>
              <p>
                <b>Obligated Amount</b> is the specific amount of money that has been committed by the federal
                government through a definite agreement or contract. This amount has been legally set aside for payment
                to the recipient for the work or services specified.
              </p>
              <p>
                <b>Current Amount</b> is the total amount of funds that have been obligated to date under a particular
                award or contract. This figure includes the initial award amount plus any modifications, amendments, or
                additional funding that has been committed since the initial award.
              </p>
              <p>
                <b>Potential Amount</b> refers to the maximum possible amount that could be awarded or obligated over
                the life of the contract or grant. It&apos;s an estimate of the total funds that could be made available
                under certain conditions, including options or future phases that might be enacted.
              </p>
            </InfoTooltip>
          </span>
        }
        {...cardListItemProps}
      >
        <DescriptionListField label="Outlayed Amount" value={asCurrency(outlayed)} />
        <DescriptionListField label="Obligated Amount" value={asCurrency(obligated)} />
        <DescriptionListField label="Current Award Amount" value={asCurrency(total)} />
        <DescriptionListField label="Potential Award Amount" value={asCurrency(potential)} />
        <div>
          <InfoTooltip
            target={<DescriptionListField label="Remaining Potential" value={asCurrency(potential - outlayed)} />}
          >
            (Potential Award Amount) - (Outlayed Amount)
          </InfoTooltip>
        </div>
        <div>
          <InfoTooltip
            target={
              <DescriptionListField
                label="Percent of Potential Remaining"
                value={asPercentage((potential - outlayed) / potential)}
              />
            }
          >
            (Remaining Potential) / (Potential Award Amount)
          </InfoTooltip>
        </div>
      </CardListItem>

      <CardListItem
        title={
          <span className="flex items-center gap-1">
            Award Dates
            <InfoTooltip>
              <p>
                <b>Action Date</b> is the date on which a specific action related to the award or contract takes place.
                Actions can include modifications, amendments, or the initial issuance of the award.
              </p>
              <p>
                <b>Start Date</b> is the start date is the date when the period of performance or the official
                commencement of work or services under the award or contract begins. This is when the recipient is
                expected to begin the activities outlined in the award.
              </p>
              <p>
                <b>Current End Date</b> is the date on which the award or contract is scheduled to end based on the most
                recent modifications or extensions. It reflects the current understanding of when the period of
                performance will conclude, without considering any unexercised options or future extensions.{' '}
              </p>
              <p>
                <b>Potential End Date</b> is the latest possible date that the award or contract may be active,
                including all possible extensions, options, and renewals. It represents the maximum duration over which
                the contract or award could be extended if all available options are exercised.
              </p>
            </InfoTooltip>
          </span>
        }
        {...cardListItemProps}
      >
        <DescriptionListField label="Start Date" value={award.periodOfPerformanceStartDate} />
        <DescriptionListField label="Current End Date" value={award.periodOfPerformanceCurrentEndDate} />
        <DescriptionListField label="Potential End Date" value={award.periodOfPerformancePotentialEndDate} />
      </CardListItem>

      <CardListItem title="Competition" {...cardListItemProps}>
        <DescriptionListField label="Solicitation Procedure" value={award.solicitationProcedures} />
        <DescriptionListField label="Extent Competed" value={award.extentCompeted} />
        <DescriptionListField
          label="Other Than Full and Open Competition"
          value={award.otherThanFullAndOpenCompetition}
        />
        <DescriptionListField label="Set-Aside Type" value={award.typeOfSetAside} />
        <DescriptionListField label="Number of Offers Received" value={award.numberOfOffersReceived} />
      </CardListItem>

      <CardListItem title="Executive Compensation" {...cardListItemProps}>
        {OFFICER_NUMBERS.map(i => {
          const nameAndAmount = [
            award[`highlyCompensatedOfficer${i}Name`],
            award[`highlyCompensatedOfficer${i}Amount`]
          ].filter(Boolean);
          const value = nameAndAmount.join(' - ') || '-';
          return <DescriptionListField key={i} label={`Officer ${i}`} value={value} />;
        })}
      </CardListItem>
    </CardList>
  );
};

const cardListItemProps: CardListItemProps = {
  collapsible: true,
  compact: true,
  defaultIsOpen: true,
  titleRenderer: props => <CardHeading {...props} className="text-base" />,
  className: 'space-y-2'
};

const OFFICER_NUMBERS = [1, 2, 3, 4, 5] as const;
