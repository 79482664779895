export const DRAWER_PARAMS = {
  type: 'drawerType',
  id: 'drawerId',
  size: 'drawerSize',
  tab: 'drawerTab'
} as const;

export const DRAWER_TYPES = {
  opp: 'opportunities',
  workspace: 'workspaces',
  contact: 'contacts',
  feed: 'feed',
  managedFeed: 'managed_feed',
  award: 'award',
  governmentEntity: 'entity'
} as const;

export type DrawerType = (typeof DRAWER_TYPES)[keyof typeof DRAWER_TYPES];
