import { useCallback } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { DirectUpload, Blob } from '@rails/activestorage';
import { dangerToast, uploadToast } from 'app/lib/toaster';
import { OnAttachArgs, useOnBulkAttach } from './useOnBulkAttach';

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads';

export type { OnAttachArgs };
type OnProgressArgs = { percentComplete: number; filename: string; id: string };
type OnErrorArgs = { filename: string; id: string };

const onProgress = async ({ percentComplete, filename, id }: OnProgressArgs) => {
  await uploadToast({ key: id, amount: percentComplete, filename });
};

const onError = async ({ filename, id }: OnErrorArgs) => {
  await dangerToast(`Failed to upload ${filename}`, id);
};

export type UseUploadArgs = {
  uploaderId: string;
  onAttach?: ({ name, signedId, id }: OnAttachArgs) => Promise<void>;
  onBulkAttach?: (attachments: OnAttachArgs[]) => Promise<void>;
  onInitialize?: (file: File) => void;
} & Omit<DropzoneOptions, 'onError'>;

type UploadKey = keyof XMLHttpRequestEventTargetEventMap;
export const useUpload = ({ uploaderId, onAttach, onBulkAttach, onInitialize, ...rest }: UseUploadArgs) => {
  if (!onAttach && !onBulkAttach) {
    throw new Error('onAttach or onBulkAttach must be provided');
  }

  const { onBulkAttach: handleBulkAttachment, isBulk } = useOnBulkAttach({ onBulkAttach });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file, index) => {
        onInitialize?.(file);
        const uploadId = `${uploaderId}-${file.name.replace(/\s/g, '-')}-${index}`;
        await onProgress({
          percentComplete: 0,
          filename: file.name,
          id: uploadId
        });

        const upload = new DirectUpload(file, DIRECT_UPLOAD_URL, {
          directUploadWillCreateBlobWithXHR: xhr => {
            xhr.upload.addEventListener('direct-upload:progress' as UploadKey, async (event: ProgressEvent) => {
              const { loaded, total } = event;
              await onProgress({
                percentComplete: (loaded / total) * 100 + 50,
                filename: file.name,
                id: uploadId
              });
            });
            xhr.upload.addEventListener('progress', async ({ loaded, total }) => {
              await onProgress({
                percentComplete: ((loaded / total) * 100) / 2,
                filename: file.name,
                id: uploadId
              });
            });
          }
        });

        const onCreate = async (error: Error, blob: Blob) => {
          if (error) {
            await onError({ filename: file.name, id: uploadId });
            throw error;
          } else {
            try {
              const attachment = {
                name: file.name,
                signedId: blob.signed_id,
                id: blob.signed_id
              };

              if (isBulk) {
                handleBulkAttachment(attachment);
              } else {
                await onAttach?.(attachment);
              }

              await onProgress({
                percentComplete: 100,
                filename: file.name,
                id: uploadId
              });
            } catch (err) {
              if (typeof err === 'object' && err && 'message' in err && typeof err.message === 'string') {
                dangerToast(err.message, uploadId);
              } else {
                await onError({ filename: file.name, id: uploadId });
              }
              throw err;
            }
          }
        };
        upload.create(onCreate);
      });
    },
    [onInitialize, uploaderId, isBulk, handleBulkAttachment, onAttach]
  );

  return useDropzone({ onDrop, multiple: isBulk, ...rest });
};
