import React from 'react';
import ReactJson from '@microlink/react-json-view';

import { base16CodeTheme } from 'app/lib/colors';

export const JsonViewer = ({ json, ...rest }: { json: object }) => (
  // @ts-expect-error we know this is strange
  <ReactJson.default
    name={false}
    theme={base16CodeTheme}
    indentWidth={2}
    displayObjectSize={false}
    displayDataTypes={false}
    displayArrayKey={false}
    quotesOnKeys={false}
    collapseStringsAfterLength={20}
    src={json}
    {...rest}
  />
);
