import React, { forwardRef, useEffect, useLayoutEffect, useRef, MutableRefObject } from 'react';

import { cn } from 'app/lib/cn';

import { trixAutoLink } from 'app/lib/trixAutoLink';
import { setupMentions, SetMentions, LimitedUser } from 'app/lib/trixMentions';
import { FormGroup, FormGroupProps } from '@blueprintjs/core';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { EditorToolbar } from 'app/molecules/Editor/EditorToolbar';
import { TrixEditorEvent } from 'trix';

type EditorProps = {
  id: string;
  onChange: (event: TrixEditorEvent) => void;
  inputProps?: {
    value: string;
  };
  trixEditorProps?: Record<string, unknown>;
  trixToolbarProps?: Record<string, unknown>;
  notifiableUsers?: LimitedUser[];
  setMentions?: SetMentions;
  formGroupProps?: FormGroupProps;
  hideUploads?: boolean;
  error?: boolean;
};

export const Editor = forwardRef<HTMLTextAreaElement, EditorProps>(
  (
    {
      id,
      onChange,
      inputProps = {},
      trixEditorProps = {},
      trixToolbarProps = {},
      error,
      notifiableUsers,
      setMentions,
      formGroupProps = {},
      hideUploads = false
    },
    ref
  ) => {
    const { data: currentUser } = useGetCurrentUserQuery();
    const defaultRef = useRef<HTMLTextAreaElement>(null);
    const editorRef = (ref as MutableRefObject<HTMLTextAreaElement>) || defaultRef;

    useLayoutEffect(() => {
      const currRef = editorRef.current;
      if (inputProps.value) {
        currRef.value = inputProps.value;
      }

      const setMentionsListener = (event: Event) => setupMentions(event, setMentions);

      currRef.addEventListener('trix-initialize', trixAutoLink);
      currRef.addEventListener('trix-initialize', setMentionsListener);

      if (currentUser?.compliancePreventUploads || hideUploads) {
        currRef.addEventListener('trix-initialize', () =>
          document.querySelector('.trix-button-group--file-tools')?.remove()
        );
        currRef.addEventListener('trix-file-accept', (event: Event) => event.preventDefault());
      }

      return () => {
        currRef.removeEventListener('trix-initialize', setMentionsListener);
        currRef.removeEventListener('trix-initialize', trixAutoLink);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setupMentions, trixAutoLink, editorRef, currentUser, hideUploads]);

    useEffect(() => {
      const currRef = editorRef.current;

      const onChangeListener = (event: Event) => onChange(event as TrixEditorEvent);

      currRef.addEventListener('trix-change', onChangeListener);

      if (setMentions) {
        editorRef.current.dataset.mentionables = JSON.stringify(notifiableUsers); // accessed in trixMentions
      }

      return () => {
        currRef.removeEventListener('trix-change', onChangeListener);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, editorRef]);

    return (
      <FormGroup {...formGroupProps}>
        <div className="prose prose-sm" data-test="editor">
          <trix-toolbar id={`${id}-toolbar`} {...trixToolbarProps}>
            <EditorToolbar hideUploads={hideUploads} />
          </trix-toolbar>
          <input type="hidden" id={id} name="content" {...inputProps} />
          <trix-editor
            toolbar={`${id}-toolbar`}
            class={cn(
              'mt-1 block w-full rounded-md border border-gray-300 p-4 shadow-sm outline-none focus:border-2 focus:border-blue-300 focus:ring-blue-300 sm:text-sm',
              { 'border-red-500': error }
            )}
            input={id}
            ref={editorRef}
            data-direct-upload-url="/rails/active_storage/direct_uploads"
            data-blob-url-template="/rails/active_storage/blobs/proxy/:signed_id/:filename"
            {...trixEditorProps}
          />
        </div>
      </FormGroup>
    );
  }
);

Editor.displayName = 'Editor';
