import DOMPurify from 'dompurify';
import React from 'react';

import { Expander } from 'app/atoms/Expander/Expander';
import { cn } from 'app/lib/cn';

export const OppDetailsDescription = ({ description }: { description: string }) => {
  if (!description) return null;

  let sanitized = DOMPurify.sanitize(description);
  sanitized = sanitized.replaceAll(/\n{2,}/g, '\n');
  if (!sanitized.length) {
    return null;
  }

  const hasHTML = /<\/?[a-z][\s\S]*>/i.test(sanitized);

  return (
    <div className={cn('sm:gap-4 px-5 py-2 sm:col-span-2')}>
      <dt className="text-sm font-medium leading-6">Description</dt>
      <dd className="pt-4 pb-4 text-sm leading-6 sm:col-span-2 sm:mt-0">
        <Expander minHeight={100} className={cn('prose prose-sm', { 'whitespace-pre-line': !hasHTML })}>
          <div dangerouslySetInnerHTML={{ __html: sanitized }} />
        </Expander>
      </dd>
    </div>
  );
};
