import React from 'react';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { Icon, Button, Tooltip } from '@blueprintjs/core';
import { CardList } from 'app/molecules/CardList/CardList';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { FileUploader } from 'app/molecules/FileUploaders/FileUploader/FileUploader';

import { QuoteShow as Quote } from 'types/__generated__/GovlyApi';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { useCreateQuoteMutation } from 'api/quotesApi';
import {
  useOppWorkspaceStore,
  useOppWorkspaceStoreRef
} from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { OppWorkspaceQuoteListItem } from './OppWorkspaceQuoteListItem';
import { AcceptedFileTypes } from './quoteFileTypes';

type OppWorkspaceQuoteFormProps = {
  quotes: Quote[];
};

export function OppWorkspaceQuoteForm({ quotes }: OppWorkspaceQuoteFormProps) {
  const storeRef = useOppWorkspaceStoreRef();
  const workspaceId = useOppWorkspaceStore(state => state.workspaceId);

  const { trackEvent } = useEventTracking();
  const [createQuote] = useCreateQuoteMutation();

  const onClick = () => trackEvent({ object: 'quote', action: 'file_uploader_clicked', properties: { workspaceId } });

  const onAttach = async ({ signedId: file }: { signedId: string }) => {
    const res = await createQuote({ workspaceId: workspaceId ?? '', file });
    if ('error' in res) {
      throw new Error("We're sorry, something went wrong. Please try again later.");
    }
    storeRef.setState({ shouldPollQuotes: true });
  };

  return (
    <ErrorBoundary action="OppWorkspaceQuoteForm" fallback={<CardError title="Quotes" />}>
      <CardList
        title="Quotes"
        rightElement={
          <FileUploader
            id={'opp-workspace-quote-zone-dropzone'}
            wrapperElement="div"
            useUploadArgs={{
              maxFiles: 1,
              accept: AcceptedFileTypes
            }}
            inputProps={{
              id: `opp-workspace-quote-form-dropzone-input`
            }}
            onAttach={onAttach}
          >
            <Tooltip content={`Upload a new quote`}>
              <Button icon="plus" onClick={onClick} />
            </Tooltip>
          </FileUploader>
        }
      >
        {quotes.length === 0 ? (
          <FileUploader
            id={'opp-workspace-quote-zone-dropzone'}
            wrapperElement="div"
            className="relative px-4 py-5 lg:p-6"
            useUploadArgs={{
              maxFiles: 1,
              accept: AcceptedFileTypes
            }}
            inputProps={{
              className: 'absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md',
              id: `opp-workspace-quote-form-dropzone-input`
            }}
            onAttach={onAttach}
          >
            <div
              className="group border-2 border-gray-200 border-dashed rounded-2xl p-4 flex flex-col items-center hover:cursor-pointer hover:bg-gray-50"
              onClick={onClick}
            >
              <Icon icon="cloud-upload" className="text-gray-500" size={20} />
              <span className="text-gray-500">
                <span className="group-hover:underline">
                  Choose a PDF or drop one here to upload into this workspace.
                </span>
              </span>
            </div>
          </FileUploader>
        ) : (
          quotes.map(quote => <OppWorkspaceQuoteListItem key={quote.id} quote={quote} />)
        )}
      </CardList>
    </ErrorBoundary>
  );
}
