import React from 'react';
import { Contact, ContactProps } from 'app/molecules/Contact/Contact';
import { Card, CardSection } from 'app/atoms/Card/Card';

type ContactsCardProps = {
  title?: string;
  contacts?: ContactProps[];
};

export const ContactsCard = ({ title = 'Contacts', contacts }: ContactsCardProps) => {
  if (!contacts || !contacts.length) {
    return null;
  }

  return (
    <Card title={title} icon="person">
      {contacts.map((contact, i) => (
        <CardSection key={`${contact.email}-${i}`}>
          <Contact {...contact} />
        </CardSection>
      ))}
    </Card>
  );
};
