import React, { useEffect } from 'react';
import { useParams, Outlet, LoaderFunction } from 'react-router-dom';

import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Main } from 'app/atoms/Main/Main';
import { oppsApi, useGetOppQuery } from 'api/oppsApi';
import { useCreateOppInteractionMutation } from 'api/oppInteractionsApi';
import { ApplicationError } from 'app/atoms/ErrorFallback/ApplicationError';
import { store } from 'app/store';
import { makeCrumbHandle } from 'app/molecules/Breadcrumbs/useBreadcrumbs';

type LoaderData = { oppId?: string };

export const loader: LoaderFunction = ({ params }): LoaderData => {
  return { oppId: params.id };
};

export const handle = makeCrumbHandle<LoaderData>(async ({ oppId }) => {
  if (!oppId) return [];
  const opp = await store
    .dispatch(oppsApi.endpoints.getOpp.initiate({ id: oppId }, { subscribe: false }))
    .then(({ data }) => data);

  return opp
    ? [
        { to: `/opportunities`, text: 'Opportunities' },
        { to: `/opportunities/${opp.id}`, text: opp.displayName }
      ]
    : [{ to: `/opportunities`, text: 'Opportunities' }];
});

export const OppDetailsLayoutPage = () => {
  const { id } = useParams();
  const { isLoading, isError } = useGetOppQuery({ id: id ?? '' }, { skip: !id });
  const [createOppInteraction, { isUninitialized }] = useCreateOppInteractionMutation();

  useEffect(() => {
    if (isUninitialized && id) {
      createOppInteraction({ oppId: id, viewed: true });
    }
  }, [isUninitialized, createOppInteraction, id]);

  if (isLoading) {
    return <AppLoading />;
  }

  if (isError) {
    return <ApplicationError />;
  }

  return (
    <Main>
      <Outlet />
    </Main>
  );
};
