import React from 'react';
import { Icon, IconName } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

type Props = React.PropsWithChildren<{
  label: string | React.ReactNode;
  size?: 'sm' | 'base' | 'lg' | 'xl';
  value?: React.ReactNode;
  cols?: 1 | 2 | 3;
  inline?: boolean;
  icon?: IconName;
  className?: string;
  innerClassName?: string;
}>;

export const DescriptionListField = ({
  label,
  value,
  cols,
  children,
  size = 'sm',
  inline = false,
  icon,
  className,
  innerClassName
}: Props) => {
  if (!value || (Array.isArray(value) && !value.length)) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex items-center gap-2',
        {
          'col-span-1': cols === 1,
          'col-span-2': cols === 2,
          'col-span-3': cols === 3
        },
        className
      )}
    >
      <div className={cn({ 'gap-x-1': inline, 'flex flex-col gap-y-1': !inline }, innerClassName)}>
        <dt
          className={cn(`text-${size} flex items-center gap-x-1 font-medium `, {
            inline: inline
          })}
        >
          <Icon icon={icon} />
          {label}
        </dt>
        <dd className={cn(`text-${size} text-gray-600`, { inline })} data-test="description-list-field-value">
          {value}
        </dd>
      </div>
      {children}
    </div>
  );
};
