import React, { ReactElement } from 'react';

import { cn } from 'app/lib/cn';
import { Card, CardProps, CardSection } from 'app/atoms/Card/Card';
import { Text } from 'app/atoms/Typography/Typography';

import { CardSectionProps } from 'app/atoms/Card/Card';
import { CollapseProps } from '@blueprintjs/core';

export type CardListItemProps = CardProps & {
  wrapperClassName?: string;
  defaultIsOpen?: boolean;
  cardSectionProps?: CardSectionProps;
  collapseProps?: CollapseProps;
};

type Child = ReactElement<CardListItemProps> | boolean | null | undefined;

export type CardListProps = Omit<CardProps, 'children'> & {
  wrapperClassName?: string;
  children: Child[] | Child;
};

export const CardList = ({ children, className, wrapperClassName, ...rest }: CardListProps) => {
  return (
    <Card role="list" className={wrapperClassName} {...rest}>
      <CardSection padded={false} className={cn('[&>*]:rounded-none [&>*]:border-0', className)}>
        {children}
      </CardSection>
    </Card>
  );
};

export const CardListItem = ({
  children,
  wrapperClassName,
  className,
  defaultIsOpen = false,
  collapseProps,
  collapsible,
  title,
  cardSectionProps = {},
  ...rest
}: CardListItemProps) => (
  <Card
    titleRenderer={Text}
    collapseProps={{ defaultIsOpen, ...collapseProps }}
    className={cn('[&>*]:border-0', wrapperClassName)}
    collapsible={collapsible}
    title={title}
    {...rest}
  >
    {children && (
      <CardSection className={cn(className)} {...cardSectionProps}>
        {children}
      </CardSection>
    )}
  </Card>
);
