import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { DrawerNavItem } from './AuthenticatedNavItemsUtils';

export function useSelectDrawerNavItem() {
  const [, setSearchParams] = useSearchParams();

  return useCallback(
    (node: Extract<DrawerNavItem, { type: 'tab' }>) => {
      if (!node) return;

      setSearchParams(prev => {
        prev.set(DRAWER_PARAMS.tab, node.tab);
        return prev;
      });
    },
    [setSearchParams]
  );
}
