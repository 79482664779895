import React from 'react';
import { Tag } from '@blueprintjs/core';
import { useFormikContext } from 'formik';
import { NavLink } from 'react-router-dom';
import { QueryInputMultiSelect } from 'app/molecules/QueryInputMultiSelect/QueryInputMultiSelect';
import { CardList } from 'app/molecules/CardList/CardList';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { useGetFormikFilterCardListItemProps } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { FormikToBp } from 'app/lib/formikToBp';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { useAwardTypeaheadQuery } from 'api/awardsApi';
import { defaultValues, AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { getFiltersCount } from 'app/hooks/useGetFormikFilterCountByField';
import { useGetNaicsCodesQuery, useGetPscCodesQuery } from 'api/classificationCodesApi';
import { FuzzyInputMultiSelect } from 'app/molecules/InputMultiSelect/FuzzyInputMultiSelect';
import { TypeaheadItem, ClassificationCodeTypeahead } from 'types/__generated__/GovlyApi';
import { US_CONGRESSIONAL_DISTRICT_ABBRS } from 'app/lib/usCongressionalDistricts';
import { FormikCompositeQueryMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeQueryMultiSelect';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';
import { FormikCompositeUserMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeUserMultiSelect';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { AwardSearchFiltersAmounts } from 'app/organisms/AwardSearchFilters/AwardSearchFiltersAmounts';
import { AwardSearchFiltersDateRange } from 'app/organisms/AwardSearchFilters/AwardSearchFiltersDateRange';
import { AwardSearchFiltersAgency } from 'app/organisms/AwardSearchFilters/AwardSearchFiltersAgency';
import { AwardSearchFiltersAwardType } from 'app/organisms/AwardSearchFilters/AwardSearchFiltersAwardType';

type AwardSearchFiltersProps = {
  wrapperClassName?: string;
  className?: string;
};

const congressionalDistricts = US_CONGRESSIONAL_DISTRICT_ABBRS.map(abbr => ({ value: abbr, label: abbr }));

export const AwardSearchFilters = ({ className, wrapperClassName }: AwardSearchFiltersProps) => {
  const { values, setValues, submitForm } = useFormikContext<AwardSearchForm>();
  const getFilterCardProps = useGetFormikFilterCardListItemProps<AwardSearchForm>();
  const filtersCount = getFiltersCount(values, v => v.filters);

  const getField = useGetFormikField<AwardSearchForm>();
  const renderKey = values.meta.searchState;

  return (
    <CardList
      key={renderKey}
      title="Filter"
      wrapperClassName={wrapperClassName}
      className={className}
      rightElement={
        filtersCount > 0 ? (
          <Tag
            onRemove={e => {
              e.preventDefault();
              const { filters } = defaultValues;
              setValues({ ...values, filters });
              submitForm();
            }}
          >
            {filtersCount}
          </Tag>
        ) : undefined
      }
    >
      <AwardSearchFiltersAwardType />

      <AwardSearchFiltersDateRange />

      <AwardSearchFiltersAmounts />

      <AwardSearchFiltersAgency />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={[
          ['filters.recipientNames.any', 'Include'],
          ['filters.recipientNames.none', 'Exclude']
        ]}
        title="Recipient"
        useHook={useAwardTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'recipient_name' }]}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemFallback={value => ({ value, label: value }) as TypeaheadItem}
        multiselectType="async"
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={[
          ['filters.programs.any', 'Include'],
          ['filters.programs.none', 'Exclude']
        ]}
        title="Program"
        useHook={useAwardTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[
          {
            typeahead: 'program',
            filterValues: ['Unknown']
          }
        ]}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemFallback={value => ({ value, label: value }) as TypeaheadItem}
      />

      <InputFilterCardListItem
        title="Place Of Performance"
        {...getFilterCardProps({ names: ['filters.placeOfPerformance'] })}
      >
        <QueryInputMultiSelect
          useHook={useAwardTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
          hookArgs={[{ typeahead: 'pop_country_name', filterValues: ['Unknown'] }]}
          getItemValue={item => item.value}
          getItemsBasedProps={(items: TypeaheadItem[]) =>
            FormikToBp.toMultiSelect({
              ...getField('filters.placeOfPerformance.countries'),
              items,
              getItemValue: item => item.value
            })
          }
          getItemTextLabel={item => item.label ?? ''}
          useTypeaheadProps={{ options: { keys: ['label'] } }}
          placeholder="Country"
        />

        <QueryInputMultiSelect
          useHook={useAwardTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
          hookArgs={[{ typeahead: 'pop_region_name', filterValues: ['Unknown'] }]}
          getItemValue={item => item.value}
          getItemsBasedProps={(items: TypeaheadItem[]) =>
            FormikToBp.toMultiSelect({
              ...getField('filters.placeOfPerformance.regions'),
              items,
              getItemValue: item => item.value
            })
          }
          getItemTextLabel={item => item.label ?? ''}
          useTypeaheadProps={{ options: { keys: ['label'] } }}
          placeholder="Region"
        />

        <FuzzyInputMultiSelect
          placeholder="Congressional District"
          items={congressionalDistricts}
          getItemValue={item => item.value}
          useTypeaheadProps={{ options: { keys: ['label'] } }}
          {...FormikToBp.toMultiSelect({
            ...getField('filters.placeOfPerformance.congressionalDistricts'),
            items: congressionalDistricts,
            getItemValue: item => item.value
          })}
          getItemTextLabel={item => item.label}
        />
      </InputFilterCardListItem>

      <FormikCompositeUserMultiSelect
        title="Following"
        namesAndLabels={[
          ['filters.following.any', 'Include'],
          ['filters.following.all', 'Include all'],
          ['filters.following.none', 'Exclude']
        ]}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={[
          ['filters.labels.any', 'Include'],
          ['filters.labels.all', 'Include all'],
          ['filters.labels.none', 'Exclude']
        ]}
        title="Labels"
        useHook={useGetOrganizationLabelsQuery}
        useTypeaheadProps={{ options: { keys: ['name', 'description'] } }}
        getItemTextLabel={item => item.name ?? ''}
        getItemLabel={label => <LabelTag {...label} />}
        getItemValue={item => item.id}
        getInputProps={({ index }) =>
          index === 0
            ? {
                formGroupProps: {
                  helperText: <NavLink to={'/settings/labels'}>Manage labels</NavLink>
                }
              }
            : {}
        }
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={[
          ['filters.searchTags.any', 'Include'],
          ['filters.searchTags.all', 'Include all'],
          ['filters.searchTags.none', 'Exclude']
        ]}
        title="Tags"
        useHook={useAwardTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'search_tags', filterValues: ['Unknown'], per: 500 }]}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={[
          ['filters.setAsides.any', 'Include'],
          ['filters.setAsides.none', 'Exclude']
        ]}
        title="Set Aside"
        useHook={useAwardTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'set_aside', filterValues: ['Unknown'] }]}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={[
          ['filters.naicsCodes.any', 'Include'],
          ['filters.naicsCodes.none', 'Exclude']
        ]}
        title="NAICS Codes"
        useHook={useGetNaicsCodesQuery as InputMultiSelectHook<ClassificationCodeTypeahead>}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={[
          ['filters.productOrServiceCodes.any', 'Include'],
          ['filters.productOrServiceCodes.none', 'Exclude']
        ]}
        title="PSC"
        useHook={useGetPscCodesQuery as InputMultiSelectHook<ClassificationCodeTypeahead>}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
      />
    </CardList>
  );
};
