import React from 'react';
import { cn } from 'app/lib/cn';

type MainProps = React.PropsWithChildren<{ className?: string; gridClassName?: string; containerClassName?: string }>;

export const Main = ({ children, className, gridClassName, containerClassName, ...rest }: MainProps) => {
  return (
    <div className={cn('min-h-full bg-gray-50', containerClassName)} {...rest}>
      <main className={cn('mx-auto lg:px-4 py-5', className)}>
        <div className={cn('grid grid-cols-12 gap-y-8 sm:gap-x-4', gridClassName)}>{children}</div>
      </main>
    </div>
  );
};
