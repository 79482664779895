import React from 'react';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { Card, CardSection } from 'app/atoms/Card/Card';

type AddressProps = {
  city?: string;
  country?: string;
  place?: string;
  postalCode?: string;
  region?: string;
  street?: string;
  type?: string;
  countryCode?: string;
  className?: string;
};

type AddressCardProps = {
  title?: string;
  addresses?: AddressProps[];
};

export const AddressesCard = ({ title = 'Locations', addresses }: AddressCardProps) => {
  if (!addresses || !addresses.length) {
    return null;
  }

  return (
    <Card title={title} icon="map">
      {addresses.map(({ city, country, place, postalCode, region, street, type }, i) => (
        <CardSection key={`${postalCode}-${i}`}>
          <div className="grid grid-cols-3 gap-y-2">
            <DescriptionListField label="Place" value={place} cols={3} />
            <DescriptionListField label="Type" value={type} />
            <DescriptionListField label="City" value={city} />
            <DescriptionListField label="Region" value={region} />
            <DescriptionListField label="Street" value={street} />
            <DescriptionListField label="Postal Code" value={postalCode} />
            <DescriptionListField label="Country" value={country} />
          </div>
        </CardSection>
      ))}
    </Card>
  );
};
