import React, { useReducer, useEffect, useRef } from 'react';
import { FormGroup, AnchorButton, Button, Tooltip, Tag, FormGroupProps } from '@blueprintjs/core';
import lodashGet from 'lodash-es/get';
import { highlight } from 'prismjs';
import { useField, useFormikContext } from 'formik';

import { cn } from 'app/lib/cn';
import { supportChatMessage } from 'app/lib/support-chat';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import {
  insidePair,
  insideQuote,
  onQueryChangeEvent,
  onQueryKeyDownEvent,
  formatQuery,
  querystringMarkup
} from 'app/lib/searchQueryInput';

type State = {
  formatVerbose: boolean;
  highlightContext: string;
  currSelectionStart: number | null;
  currSelectionEnd: number | null;
};

const reducer = (state: Partial<State>, action: Partial<State>) => {
  return { ...state, ...action };
};

export type SearchQueryInputProps = {
  name: string;
  loading?: boolean;
  placeholder?: string;
  includeButton?: boolean;
} & FormGroupProps;

export const SearchQueryInput = ({
  name,
  className,
  label,
  disabled,
  loading,
  placeholder,
  includeButton,
  ...rest
}: SearchQueryInputProps) => {
  const { submitForm } = useFormikContext();
  const [field, _meta, { setValue }] = useField<string>({ name });
  const content = field.value;
  const ref = useRef<HTMLTextAreaElement>(null);
  const [state, dispatch] = useReducer(reducer, { highlightContext: content });
  const { currSelectionStart, currSelectionEnd, highlightContext, formatVerbose } = state;

  useEffect(() => {
    dispatch({ highlightContext: highlight(content, querystringMarkup, '') });
  }, [content]);
  useEffect(() => {
    const textArea = ref.current || { style: { height: undefined }, scrollHeight: 0 };
    textArea.style.height = '1px';
    textArea.style.height = `${textArea.scrollHeight < 100 ? 100 : textArea.scrollHeight}px`;
  }, [content, ref]);
  useEffect(() => {
    if (currSelectionStart !== null && currSelectionEnd !== null) {
      if (ref.current != null) {
        ref.current.setSelectionRange(currSelectionStart ?? null, currSelectionEnd ?? null);
      }
      dispatch({ currSelectionStart: null, currSelectionEnd: null });
    }
  }, [ref, currSelectionStart, currSelectionEnd, dispatch, content]);
  const inPhrase = insideQuote(content, ref.current ? ref.current.selectionEnd : content.length);
  const insideParen = insidePair(content, ref.current ? ref.current.selectionEnd : content.length, '(', ')');
  const helperDisabled = !content || !content.trim().length || inPhrase || disabled;
  const onChangeOptions = {
    setContent: (v: string) => setValue(v),
    setSelectionStart: (v: number) => dispatch({ currSelectionStart: v }),
    setSelectionEnd: (v: number) => dispatch({ currSelectionEnd: v }),
    onEnter: () => submitForm()
  };
  const onHelperClick = ({ key }: { key: string }) => {
    const target = ref.current ?? undefined;
    const e = { key, preventDefault: () => {}, target };

    target?.focus();

    onQueryKeyDownEvent({
      e,
      ...onChangeOptions
    });
  };
  const errors = [];
  if (insidePair(content, content.length, '(', ')')) {
    errors.push('unclosed parenthesis');
  }
  if (insideQuote(content, content.length)) {
    errors.push('unclosed quote');
  }

  const textClass =
    'm-0 w-full overflow-y-auto whitespace-pre-wrap break-words border-0 p-2 text-base caret-gray-900 dark:caret-white';
  return (
    <FormGroup
      className={cn('m-0', className)}
      label={<LabelSpan label={label} />}
      labelFor={name}
      contentClassName="mt-2"
      labelInfo={
        !!errors.length && (
          <Tag minimal icon="warning-sign" intent="danger">
            {`${errors.join(' and ')}`}
          </Tag>
        )
      }
      helperText={
        <div className="flex justify-between">
          <div className="hidden flex-wrap justify-between sm:flex">
            <div className="hidden flex-wrap justify-between sm:flex">
              <Tooltip content="Exact phrase">
                <AnchorButton
                  disabled={inPhrase || disabled}
                  onClick={() => onHelperClick({ key: '"' })}
                  text='" "'
                  className="mr-2 mb-2 font-semibold text-blue-700"
                  outlined
                />
              </Tooltip>
              <Tooltip content="Group parts">
                <AnchorButton
                  disabled={inPhrase || disabled}
                  onClick={() => onHelperClick({ key: '(' })}
                  text="( )"
                  className="mr-2 mb-2 font-semibold text-purple-700"
                  outlined
                />
              </Tooltip>
              <Button
                disabled={helperDisabled}
                onClick={() => onHelperClick({ key: '+' })}
                text="AND"
                className="mr-2 mb-2 font-semibold text-green-700 disabled:bg-inherit disabled:text-green-700/50"
                outlined
              />
              <Button
                disabled={helperDisabled}
                onClick={() => onHelperClick({ key: '|' })}
                text="OR"
                outlined
                className="mr-2 mb-2 font-semibold text-cyan-700 disabled:bg-inherit disabled:text-cyan-700/50"
              />
              <Button
                disabled={inPhrase || insideParen || disabled}
                onClick={() => onHelperClick({ key: '-' })}
                text="NOT"
                className="mr-2 mb-2 font-semibold text-red-600 disabled:bg-inherit disabled:text-red-600/50"
                outlined
              />
              <Tooltip content="Format for readability">
                <Button
                  onClick={() => {
                    const formatted = formatQuery(content, !formatVerbose);
                    setValue(formatted);
                    dispatch({
                      formatVerbose: !formatVerbose
                    });
                  }}
                  disabled={disabled}
                  text="FORMAT"
                  className="mr-2 mb-2 font-semibold text-orange-600 disabled:bg-inherit disabled:text-orange-600/50"
                  outlined
                />
              </Tooltip>
            </div>
          </div>
          {includeButton ? (
            <Button loading={loading} type="submit" intent="primary">
              Search
            </Button>
          ) : (
            <span className="text-sm">
              Having problems with the search engine?{' '}
              <a
                onClick={e => {
                  e.preventDefault();
                  supportChatMessage('Can you help me create a new search?');
                }}
              >
                Ask for help.
              </a>
            </span>
          )}
        </div>
      }
      {...rest}
    >
      <div className="mb-2">
        <div
          className="relative rounded bg-transparent"
          style={{
            height: `${lodashGet(ref.current, 'scrollHeight') || 100}px`
          }}
        >
          <textarea
            data-test="opp-search-input"
            className={cn(textClass, 'bp5-input bp5-large resize-none text-white/0')}
            name={name}
            disabled={disabled}
            value={content}
            spellCheck="false"
            autoCorrect="false"
            placeholder={placeholder || 'servers AND (oracle OR dell) NOT(printers OR monitors)'}
            onChange={e => {
              onQueryChangeEvent({ e, ...onChangeOptions });
            }}
            onKeyDown={e =>
              onQueryKeyDownEvent({
                e: e as React.KeyboardEvent<HTMLTextAreaElement> & { target: HTMLTextAreaElement },
                ...onChangeOptions
              })
            }
            ref={ref}
          />
          <pre
            className={cn(textClass, 'pointer-events-none absolute left-0 top-0 z-5 bg-transparent')}
            style={{ color: 'inherit', fontFamily: 'inherit' }}
            dangerouslySetInnerHTML={{ __html: highlightContext ?? '' }}
          />
        </div>
      </div>
    </FormGroup>
  );
};
