import React from 'react';
import { NavLinkButton } from 'app/molecules/NavLinkButton/NavLinkButton';
import { NavItem } from 'app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { EntityTitle, Tag, Tooltip } from '@blueprintjs/core';
import { useNavStore } from 'app/organisms/AuthenticatedNav/useNavStore';

export type BudgetsNavItemProps = {
  node: NavItem;
  index: number;
};

export const BudgetsNavItem = ({ node, index }: BudgetsNavItemProps) => {
  const { isExpanded } = useNavStore();

  return (
    <Tooltip
      className="w-full"
      content={
        <EntityTitle
          title="Budgets"
          tags={
            <Tag intent="primary" round>
              BETA
            </Tag>
          }
          subtitle={
            <p className="max-w-sm">
              We are actively improving and refining this feature, but want to give you access as soon as possible.
              Please let us know if you have any feedback or suggestions!
            </p>
          }
        />
      }
    >
      <NavLinkButton
        key={index}
        to={node.to}
        buttonProps={
          isExpanded
            ? {
                icon: node.icon,
                text: node.label,
                rightIcon: (
                  <Tag intent="primary" minimal round>
                    BETA
                  </Tag>
                )
              }
            : {
                icon: node.icon
              }
        }
        forceCurrent={node.current}
      />
    </Tooltip>
  );
};
