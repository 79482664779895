import React from 'react';
import { Button, ButtonGroup, ButtonGroupProps } from '@blueprintjs/core';

import { useLocalStorage } from 'app/hooks/useLocalStorage';
import { successToast } from 'app/lib/toaster';

export type SimpleFeedbackProps = {
  detractorText: string;
  promoterText: string;
  feedbackGroup: string;
  cacheKey: string;
} & Partial<Omit<ButtonGroupProps, 'children'>>;

export const SimpleFeedback = ({
  detractorText,
  promoterText,
  feedbackGroup,
  cacheKey,
  ...rest
}: SimpleFeedbackProps) => {
  const [hasGivenFeedback, setHasGivenFeedback] = useLocalStorage(cacheKey, false);

  if (hasGivenFeedback) {
    return null;
  }

  const handleClick = async () => {
    await successToast('Feedback submitted');
    setHasGivenFeedback(true);
  };

  return (
    <ButtonGroup minimal {...rest}>
      <Button
        onClick={handleClick}
        icon="thumbs-up"
        intent="success"
        aria-label="Promote"
        data-ph-capture-attribute-feedback-group={feedbackGroup}
        data-ph-capture-attribute-feedback-key={cacheKey}
      >
        {promoterText}
      </Button>
      <Button
        onClick={handleClick}
        icon="thumbs-down"
        intent="danger"
        aria-label="Detract"
        data-ph-capture-attribute-feedback-group={feedbackGroup}
        data-ph-capture-attribute-feedback-key={cacheKey}
      >
        {detractorText}
      </Button>
    </ButtonGroup>
  );
};
