import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormGroup, Button, Tag, Tooltip, NonIdealState } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import startCase from 'lodash-es/startCase';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { MultiSelectInput } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { errorToast, successToast } from 'app/lib/toaster';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { AvatarUploader } from 'app/molecules/AvatarUploader/AvatarUploader';
import { useCreateOrganizationUserMutation, useUpdateOrganizationUserMutation } from 'api/organizationUsersApi';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { OrganizationUserCurrentOrg, OrganizationTeam } from 'types/__generated__/GovlyApi';
import { isFetchBaseQueryError, hasDataErrors, hasId } from 'api/utils';
import { useGetOrganizationTeamsQuery } from 'api/organizationTeamsApi';

const roleTooltip = {
  member: 'Users with this role can only access their own profile and settings.',
  admin: 'Users with this role can add, remove and edit users.'
};

const roleLabel = (role: keyof typeof roleTooltip) => {
  if (!roleTooltip[role]) return role;

  return (
    <Tooltip className="cursor-help" content={roleTooltip[role]}>
      {startCase(role)}
    </Tooltip>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  role: yup.string().required('Role is required.'),
  email: yup.string().email('Email is invalid.').required('Email is required.')
});

type UserProfileFormProps = {
  organizationUser?: OrganizationUserCurrentOrg;
};

export const UserProfileForm = ({ organizationUser }: UserProfileFormProps) => {
  const {
    id,
    avatar,
    initials,
    sysAdmin,
    name = '',
    email = '',
    phoneNumber = '',
    primaryRole = 'member',
    subscriptionSeatPaid = true,
    organizationTeams = []
  } = organizationUser || {};

  const navigate = useNavigate();
  const { trackEvent } = useEventTracking();
  const [tempAvatarURL, setTempAvatarURL] = useState<string>();
  const adminViewer = useAuthorized({ role: 'admin' });
  const sysAdminViewer = useAuthorized({ role: 'sys_admin' });
  const currentOrgUserId = useCurrentUserAttribute('id');
  const [updateOrganizationUser] = useUpdateOrganizationUserMutation();
  const [createOrganizationUser] = useCreateOrganizationUserMutation();
  const { data: extantTeamsData = [], isLoading: extantTeamsLoading } = useGetOrganizationTeamsQuery({});
  const extantTeams = extantTeamsData as OrganizationTeam[];
  const organizationTeamIds = useMemo(() => organizationTeams?.map(t => t.id), [organizationTeams]);

  const isEditingSelf = currentOrgUserId === id;

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        id,
        name,
        email,
        phoneNumber,
        subscriptionSeatPaid,
        organizationTeamIds,
        avatar,
        role: primaryRole,
        sendInvite: false,
        avatarRemoved: false
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          if (hasId(values)) {
            await updateOrganizationUser(values).unwrap();
            successToast('User updated.');
            trackEvent({
              object: 'user',
              action: 'updated',
              properties: { ...values }
            });
          } else {
            const data = await createOrganizationUser(values).unwrap();
            resetForm();

            if (values.sendInvite) {
              successToast('User invited. They should receive an email with instructions momentarily.');
              trackEvent({
                object: 'user',
                action: 'invited',
                properties: { userId: data?.id, created: true }
              });
            } else {
              successToast('User created.');
              trackEvent({
                object: 'user',
                action: 'created',
                properties: { ...values }
              });
            }
          }

          navigate('/settings/users');
        } catch (error) {
          if (error && isFetchBaseQueryError(error) && hasDataErrors(error)) {
            const { data } = error;
            if (!data) return errorToast(error);
            if (data.errors && Array.isArray(data.errors)) return data.errors.forEach(e => errorToast(e));
          }
        }
      }}
      errors
    >
      {({ handleSubmit, isSubmitting, errors, touched, setFieldValue, values }) => (
        <Form>
          <Card
            title={name ? 'Edit Profile' : 'Add User'}
            rightElement={sysAdmin ? <Tag intent="warning">SYS ADMIN</Tag> : undefined}
          >
            <CardBody>
              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                {isEditingSelf && (
                  <div className="col-span-2">
                    <FormGroup label="Avatar Image" labelFor="avatarUploader" className="mb-0">
                      <AvatarUploader
                        id="avatar"
                        initials={initials}
                        imgSrc={tempAvatarURL || (values.avatarRemoved ? undefined : values.avatar?.thumbUrl)}
                        className="m-0 bg-blue-700"
                        onRemove={() => {
                          setTempAvatarURL(undefined);
                          setFieldValue('avatar', null);
                          setFieldValue('avatarRemoved', true);
                        }}
                        onInitialize={file => {
                          setTempAvatarURL(URL.createObjectURL(file));
                        }}
                        onAttach={async attachment => {
                          setFieldValue('avatar', attachment.signedId);
                          setFieldValue('avatarRemoved', false);
                        }}
                      />
                    </FormGroup>
                  </div>
                )}
                <div className="col-span-2 lg:col-span-1">
                  <TextInput
                    name="name"
                    type="text"
                    label="Name"
                    labelInfo="(required)"
                    disabled={isSubmitting}
                    intent={errors.name && touched.name ? 'danger' : undefined}
                  />
                </div>
                {(sysAdminViewer || !id) && (
                  <div className="col-span-2 lg:col-span-1">
                    <TextInput
                      name="email"
                      type="text"
                      label="Email"
                      labelInfo="(required)"
                      disabled={!adminViewer || isSubmitting}
                      intent={errors.email && touched.email ? 'danger' : undefined}
                      inputProps={{
                        onPaste: e => {
                          const data = e.clipboardData.getData('text/plain');
                          if (!data.includes('mailto:')) return;
                          e.preventDefault();
                          window.document.execCommand('insertText', false, data.replace('mailto:', ''));
                        }
                      }}
                    />
                  </div>
                )}
                {(isEditingSelf || adminViewer) && (
                  <div className="col-span-2 lg:col-span-1">
                    <TextInput name="phoneNumber" type="text" label="Phone" disabled={isSubmitting} />
                  </div>
                )}
                {adminViewer && (
                  <div className="col-span-2 lg:col-span-1">
                    {extantTeamsLoading ? (
                      <Loading />
                    ) : !(extantTeams && extantTeams.length > 0) ? (
                      <NonIdealState />
                    ) : (
                      <MultiSelectInput
                        name="organizationTeamIds"
                        label="Teams"
                        contentClassName={''}
                        disabled={!adminViewer || isSubmitting}
                        items={extantTeams}
                        labelAttr="name"
                        valueAttr="id"
                      />
                    )}
                  </div>
                )}
                {adminViewer && (
                  <>
                    <div className="col-span-2 mt-2">
                      <SwitchInput name="subscriptionSeatPaid" label="Has paid seat?" />
                    </div>
                    <div className="col-span-2">
                      <RadioGroupInput
                        name="role"
                        label="Role"
                        labelProps={{ labelInfo: '(required)' }}
                        inline
                        options={[
                          { labelElement: roleLabel('admin'), value: 'admin' },
                          { labelElement: roleLabel('member'), value: 'member' }
                        ]}
                      />
                    </div>
                  </>
                )}
              </div>
            </CardBody>

            {(isEditingSelf || adminViewer) && (
              <CardFooter>
                {!id ? (
                  <Button
                    className="ml-2"
                    intent="success"
                    loading={isSubmitting}
                    onClick={e => {
                      setFieldValue('sendInvite', true, false);
                      // @ts-expect-error - TS doesn't like the event type here
                      handleSubmit(e);
                    }}
                  >
                    Save & Send Invite
                  </Button>
                ) : undefined}

                <Button type="submit" className="ml-2" intent="primary" loading={isSubmitting}>
                  Save
                </Button>
              </CardFooter>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
};
