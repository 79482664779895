import React from 'react';

import { CardList, CardListItem } from 'app/molecules/CardList/CardList';

export type QuestionsAndAnswersProps = {
  questions: { question?: string; answer?: string }[];
};

export const QuestionsAndAnswers = ({ questions }: QuestionsAndAnswersProps) => {
  if (!questions.length) {
    return null;
  }

  return (
    <CardList title="Questions & Answers">
      {questions.map(q => (
        <CardListItem key={q.question} title={q.question} defaultIsOpen={false} className="pt-2" collapsible>
          {q.answer}
        </CardListItem>
      ))}
    </CardList>
  );
};
