import React from 'react';
import { Button } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { useResetRackAttackMutation } from 'api/sysAdminSettingsApi';

export const SysAdminSettings = () => {
  const [resetRackAttack, { isLoading: isResettingRackAttack }] = useResetRackAttackMutation();

  return (
    <Card>
      <CardBody>
        <Button
          onClick={() => resetRackAttack()}
          loading={isResettingRackAttack}
          intent="danger"
          text="Reset throttled ips"
        />
      </CardBody>
    </Card>
  );
};
