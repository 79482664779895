import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { WorkspaceIndex } from 'types/__generated__/GovlyApi';
import { useGetOppQuery } from 'api/oppsApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';

import { possessive } from 'app/lib/strings';
import { OppWorkspacePreview } from 'app/organisms/OppWorkspacePreview/OppWorkspacePreview';
import { OppDetailsCreateWorkspace } from 'app/organisms/OppDetailsCreateWorkspace/OppDetailsCreateWorkspace';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

const columnHelper = createColumnHelper<WorkspaceIndex>();

export const OppWorkspaces = ({ id, ...rest }: React.HTMLProps<HTMLDivElement> & { id: string }) => {
  const { data: { workspaces = [] } = {}, isLoading } = useGetOppQuery({ id });
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => (
          <OppWorkspacePreview
            workspace={e.row.original}
            headerClass="flex flex-wrap gap-y-2 justify-between items-start"
            followsClass="flex flex-col gap-y-2 mt-2"
          />
        )
      })
    ],
    []
  );

  const follows = currentUser
    ? [
        {
          organizationUserId: currentUser.id,
          state: 'following' as const,
          notifications: 'user_setting' as const,
          organizationUser: currentUser.organization ? { organizationId: currentUser.organization.id } : undefined
        }
      ]
    : [];

  return (
    <div {...rest}>
      <GovlyTable
        id="organization_insights_activity_table"
        columns={columns}
        data={workspaces}
        title={<GovlyTableTitle title="Workspaces" />}
        rightElement={
          <GovlyTableToolbar>
            <OppDetailsCreateWorkspace
              name={`${possessive(currentUser?.name)} Workspace`}
              oppId={id}
              follows={follows}
              tooltipProps={{ content: 'Create a new Workspace' }}
            />
          </GovlyTableToolbar>
        }
        isLoading={isLoading || currentUserLoading}
        emptyStateProps={{ icon: 'folder-open', title: 'No workspaces' }}
        initialState={{ pagination: { pageSize: 5 } }}
      />
    </div>
  );
};
