import React from 'react';
import { Icon, IconName, Button, ButtonProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { match } from 'ts-pattern';

type TextLoadingProps = { className?: string; children?: string };

type LoadingProps =
  | {
      type?:
        | 'spinner'
        | 'feed'
        | 'table'
        | 'stacked-list'
        | 'sidebar'
        | 'flex-row'
        | 'card-list'
        | 'thumbnail-list'
        | 'condensed-card'
        | 'logo'
        | 'card'
        | 'card-body'
        | 'card-in-main'
        | 'app';
    }
  | {
      type: 'icon';
      icon?: IconName;
    }
  | {
      type: 'button';
      buttonProps?: ButtonProps;
    }
  | ({
      type: 'text';
    } & TextLoadingProps);

export const Loading = (props: LoadingProps) => {
  return match(props)
    .with({ type: 'spinner' }, () => <LoadingSpinner />)
    .with({ type: 'text' }, ({ children, className }) => <TextLoading className={className}>{children}</TextLoading>)
    .with({ type: 'feed' }, () => <FeedLoading />)
    .with({ type: 'table' }, () => <TableLoading />)
    .with({ type: 'stacked-list' }, () => <StackedListLoading />)
    .with({ type: 'sidebar' }, () => <SidebarLoading />)
    .with({ type: 'flex-row' }, () => <FlexRowLoading />)
    .with({ type: 'card-list' }, () => <CardListLoading />)
    .with({ type: 'thumbnail-list' }, () => <ThumbnailListLoading />)
    .with({ type: 'condensed-card' }, () => <CondensedCard />)
    .with({ type: 'icon' }, ({ icon }) => <IconLoading icon={icon} />)
    .with({ type: 'logo' }, () => <LogoLoading />)
    .with({ type: 'card-body' }, () => <CardBodyLoading />)
    .with({ type: 'card' }, () => <CardLoading />)
    .with({ type: 'button' }, ({ buttonProps: props }) => <ButtonLoading {...props} />)
    .with({ type: 'card-in-main' }, () => (
      <Main>
        <MainColumn>
          <CardLoading />
        </MainColumn>
      </Main>
    ))
    .with({ type: 'app' }, () => <AppLoading />)
    .otherwise(() => <CardLoading />);
};

const CardLoading = () => (
  <section>
    <div className="bg-white shadow-card dark:bg-gray-600 dark:shadow-dark sm:rounded">
      <div className="animate-pulse px-4 py-5 sm:px-6">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 w-3/4 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="space-y-2">
            <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 dark:border-gray-700 px-4 py-5 lg:p-6">
        <CardBodyLoading />
      </div>
    </div>
  </section>
);

type IconLoadingProps = { icon?: IconName };
const IconLoading = ({ icon }: IconLoadingProps) => (
  <section>
    <div className="bg-white shadow-card dark:bg-gray-600 dark:shadow-dark sm:rounded">
      <div className="border-gray-200 px-4 py-5 dark:border-gray-700 sm:px-6">
        <dl className="grid animate-pulse place-items-center">
          <Icon icon={icon || 'lock'} className="my-16 text-gray-500" size={48} />
        </dl>
      </div>
    </div>
  </section>
);

const FeedLoading = () => (
  <section>
    <div className="bg-white px-4 py-5 shadow-card dark:bg-gray-600 dark:shadow-dark sm:rounded sm:px-6">
      <div className="h-4 w-1/6 animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>

      {/* Activity Feed */}
      <div className="mt-6 flow-root animate-pulse">
        <ul role="list" className="-mb-8">
          {[0, 1, 2].map(n => (
            <li key={n}>
              <div className="relative pb-8">
                <div className="relative flex space-x-3">
                  <div className="h-8 w-8 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div className="h-4 w-5/6 rounded bg-gray-200 dark:bg-gray-700"></div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="justify-stretch mt-4 flex animate-pulse flex-col">
        <div className="h-8 rounded bg-gray-200 dark:bg-gray-700"></div>
      </div>
    </div>
  </section>
);

const TableLoading = () => (
  <section>
    <div className="overflow-hidden bg-white shadow-card dark:bg-gray-600 dark:shadow-dark sm:rounded">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-gray-200 shadow dark:border-gray-800 sm:rounded">
              <table className="min-w-full animate-pulse">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr className="border-b border-gray-200 align-top dark:border-gray-800">
                    {[1, 2, 3, 4].map(column => (
                      <th
                        key={column}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-50"
                      >
                        <div className="h-4 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-800">
                  {[1, 2, 3, 4, 5].map((row, rowIdx) => (
                    <tr
                      key={rowIdx}
                      className={cn({
                        'bg-gray-50 dark:bg-gray-700': rowIdx % 2 !== 0
                      })}
                    >
                      {[1, 2, 3, 4].map((cell, cellIdx) => (
                        <td
                          key={cellIdx}
                          className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-700 dark:text-white"
                        >
                          <div className="h-4 w-2/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const StackedListLoading = () => (
  <nav className="h-full overflow-y-auto" aria-label="data">
    {[0, 1, 2].map(grop => (
      <div key={grop} className="relative">
        <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500 dark:border-gray-800 dark:bg-gray-700 dark:text-gray-200">
          <div className="h-4 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
        </div>
        <ul role="list" className="relative z-0 divide-y divide-gray-200 dark:divide-gray-800">
          {[0, 1].map(item => (
            <li key={item} className="bg-white dark:bg-gray-600">
              <div className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset">
                <div className="min-w-0 flex-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <div className="h-4 w-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                  <div className="mt-2 h-4 w-1/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </nav>
);

const SidebarLoading = () => (
  <aside>
    <div className="animate-pulse px-4 py-5 sm:px-6">
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 w-3/4 rounded bg-gray-200 dark:bg-gray-700"></div>
        <div className="space-y-2">
          <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
        </div>
      </div>
    </div>
    <div className="px-4 py-5 dark:border-gray-700 sm:px-6">
      <dl className="grid animate-pulse grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="space-y-2 sm:col-span-2">
          <div className="h-4 w-3/4 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-4 w-1/2 rounded bg-gray-200 dark:bg-gray-700"></div>
        </div>
        <div className="space-y-2 sm:col-span-2">
          <div className="h-4 w-3/4 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-4 w-1/2 rounded bg-gray-200 dark:bg-gray-700"></div>
        </div>
        <div className="space-y-2 sm:col-span-2">
          <div className="h-4 w-1/6 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
        </div>
        <div className="space-y-2 sm:col-span-2">
          <div className="h-4 w-1/6 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
        </div>
      </dl>
    </div>
  </aside>
);

export const FlexRowLoading = () => (
  <div className="animate-pulse px-6 lg:px-0">
    <div className="flex items-center justify-between">
      <div className="flex-1 space-y-1">
        <div className="h-4 w-1/4 rounded bg-gray-200 dark:bg-gray-700"></div>
        <div className="h-4 w-1/6 rounded bg-gray-200 dark:bg-gray-700"></div>
      </div>

      <div className="h-8 w-32 rounded bg-gray-200 dark:bg-gray-700"></div>
    </div>
  </div>
);

export const TextLoading = ({ className, children = 'Loading' }: TextLoadingProps) => (
  <div className={cn('animate-pulse text-inherit relative overflow-hidden', className)}>
    <span className="inline-block">{children}</span>
    <div className="rounded bg-gray-200 dark:bg-gray-700 absolute w-full h-full top-0 left-0"></div>
  </div>
);

const CardBodyLoading = () => (
  <dl className="grid animate-pulse grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
    <CardSectionLoading />
    <CardSectionLoading />
    <CardSectionLoading />
    <CardSectionLoading />
    <div className="space-y-2 sm:col-span-2">
      <div className="h-4 w-1/6 rounded bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-4 rounded bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-4 w-1/2 rounded bg-gray-200 dark:bg-gray-700"></div>
    </div>
  </dl>
);

export const CardSectionLoading = () => (
  <div className="space-y-2 sm:col-span-1">
    <div className="h-4 w-3/4 rounded bg-gray-200 dark:bg-gray-700"></div>
    <div className="h-4 w-1/2 rounded bg-gray-200 dark:bg-gray-700"></div>
  </div>
);

const CondensedCard = () => (
  <div className="bg-white px-4 py-5 shadow-card dark:bg-gray-600 dark:shadow-dark sm:rounded sm:px-6">
    <div className="h-4 w-1/6 animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>
    <div className="justify-stretch mt-4 flex animate-pulse flex-col">
      <div className="h-8 rounded bg-gray-200 dark:bg-gray-700"></div>
    </div>
    <div className="mt-6 animate-pulse">
      <ul role="list">
        {[0, 1].map(n => (
          <li key={n} className="my-2">
            <div className="h-4 w-5/6 rounded bg-gray-200 dark:bg-gray-700"></div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const LogoLoading = () => (
  <div className="relative block h-20 w-64 animate-pulse rounded-md border-2 border-gray-300 pt-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
    <Icon icon="media" size={32} className="m-auto text-gray-300" />
    <span className="mt-2 block text-sm font-medium text-gray-300">Logo</span>
  </div>
);

const CardListLoading = () => (
  <section className="space-y-4">
    {[0, 1, 2].map(n => (
      <CondensedCard key={n} />
    ))}
  </section>
);

const ThumbnailListLoading = () => (
  <section className="mx-auto grid max-w-lg gap-4 lg:max-w-none lg:grid-cols-3">
    {[0, 1, 2].map(n => (
      <CardLoading key={n} />
    ))}
  </section>
);

const ButtonLoading = ({ className, ...props }: Partial<ButtonProps>) => (
  <Button text="Loading" className={cn('relative animate-pulse overflow-hidden', className)} {...props}>
    <div className="w-full h-full absolute top-0 left-0 bg-gray-300" />
  </Button>
);
