import React from 'react';
import Markdown from 'react-markdown';

import { EntityTitle, Icon, Tag } from '@blueprintjs/core';
import { CardList, CardListItem, CardListItemProps } from 'app/molecules/CardList/CardList';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { useGetOppQuery } from 'api/oppsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppExpirationStatus } from 'app/organisms/_Opp/OppExpirationStatus';
import { OppDetailsDescriptionFields } from 'app/organisms/OppDetailsDescriptionFields/OppDetailsDescriptionFields';
import { OppDetailsDescriptionFieldsPrediction } from 'app/organisms/OppDetailsDescriptionFieldsPrediction/OppDetailsDescriptionFieldsPrediction';
import { ImageWithFallback } from 'app/atoms/ImageWithFallback/ImageWithFallback';
import { SimpleFeedback } from 'app/molecules/SimpleFeedback/SimpleFeedback';
import { LlmGeneratedIndicator } from 'app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { OppDetailsSources } from 'app/organisms/OppDetailsSources/OppDetailsSources';
import { DescriptionList } from 'app/molecules/DescriptionList/DescriptionList';
import { NotAuthorized } from 'app/molecules/NotAuthorized/NotAuthorized';
import { OppLineItemsTable, LineItem } from 'app/organisms/OppLineItemsTable/OppLineItemsTable';
import { asCurrency } from 'app/lib/numbers';
import { GOV_DOMAIN } from 'app/lib/regex';

type SourceData = {
  contacts?: {
    email?: string;
  }[];
  buyer?: {
    hierarchy: string[];
  };
};

type SummaryData = {
  title: string;
  summary: string;
  primaryOems: string[];
  primaryCompetitors: string[];
  competingProducts: string[];
  estimatedContractValue: string[];
  productLineItems: LineItem[];
  servicesLineItems: LineItem[];
};

interface VendorWithWebsite {
  name: string;
  website: number;
}

type Vendor = VendorWithWebsite | string;

const cardListItemProps: CardListItemProps = {
  collapsible: true,
  compact: false,
  defaultIsOpen: false,
  titleRenderer: props => <CardHeadingSmall {...props} />,
  className: 'space-y-2 pt-0'
};

export const OppDetails = ({ id }: { id: string }) => {
  const { data: opp, isLoading, isError } = useGetOppQuery({ id });

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !opp) {
    return <NotAuthorized />;
  }

  const {
    isForecasted,
    displayName,
    respondBy,
    cancelledAt,
    awardedAt,
    title,
    oppSources,
    llmFullSummary,
    latestData
  } = opp;

  const analysis = (llmFullSummary || {}) as unknown as SummaryData;

  const sourceData = (latestData || {}) as unknown as SourceData;

  const domains =
    sourceData.contacts?.map(contact => contact.email?.split('@')[1]).filter(d => d && GOV_DOMAIN.test(d)) ?? [];
  const buyer = sourceData.buyer ?? opp.buyer;

  return (
    <CardList
      title={<Tag large>{displayName}</Tag>}
      className="bt-0"
      rightElement={
        isForecasted ? undefined : (
          <OppExpirationStatus
            hidePrefix={false}
            respondBy={respondBy}
            cancelledAt={cancelledAt}
            awardedAt={awardedAt}
            large
          />
        )
      }
    >
      <CardListItem
        title={title || analysis?.title || displayName}
        className="pt-0"
        titleRenderer={cardListItemProps.titleRenderer}
        defaultIsOpen
      >
        {buyer && buyer.hierarchy?.length && (
          <EntityTitle
            data-test="opp-details-display-name"
            title={buyer.hierarchy.join(', ')}
            subtitle={domains?.[0]}
            icon={
              <ImageWithFallback
                src={`https://logo.clearbit.com/${domains?.[0]}`}
                className="h-8 w-8 object-contain shrink-0 rounded-sm"
                loading="lazy"
              >
                <Icon icon="office" />
              </ImageWithFallback>
            }
          />
        )}
      </CardListItem>

      {!!analysis.summary && (
        <CardListItem
          {...cardListItemProps}
          icon={<LlmGeneratedIndicator icon="generate" />}
          title="Analysis"
          subtitle={'AI generated summary that considers all attachments and amendments.'}
          defaultIsOpen
        >
          <Markdown className="prose prose-sm pt-0 pb-4">{analysis.summary}</Markdown>
          <div className="flex gap-y-4 flex-col">
            {!!analysis?.primaryOems?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle
                  icon={<LlmGeneratedIndicator icon="generate" />}
                  heading={CardHeadingSmall}
                  title="Likely Vendors"
                />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  {analysis.primaryOems.map((vendor: Vendor) => (
                    <Tag key={typeof vendor === 'string' ? vendor : vendor.name} minimal intent="primary">
                      {typeof vendor === 'string' ? vendor : vendor.name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {!!analysis?.primaryCompetitors?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle
                  icon={<LlmGeneratedIndicator icon="predictive-analysis" />}
                  heading={CardHeadingSmall}
                  title="Possible Competing Vendors"
                />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  {analysis.primaryCompetitors.map((vendor: Vendor) => (
                    <Tag key={typeof vendor === 'string' ? vendor : vendor.name} minimal intent="primary">
                      {typeof vendor === 'string' ? vendor : vendor.name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {!!analysis?.competingProducts?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle
                  icon={<LlmGeneratedIndicator icon="predictive-analysis" />}
                  heading={CardHeadingSmall}
                  title="Possible Competing Products"
                />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  {analysis.competingProducts.map((product: string) => (
                    <Tag key={product} minimal>
                      {product}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {!!analysis?.estimatedContractValue?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle icon="predictive-analysis" heading={CardHeadingSmall} title="Estimated Contract Value" />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  <Tag minimal intent="success">
                    {analysis.estimatedContractValue.map(value => asCurrency(value, {})).join('-')}
                  </Tag>
                </div>
              </div>
            )}
            <SimpleFeedback
              detractorText="This analysis was not helpful"
              promoterText="This analysis was helpful"
              feedbackGroup="opp-details-ai-analysis-v1"
              cacheKey={`opp-details-ai-analysis-${id}`}
              className="flex flex-row-reverse"
            />
          </div>
        </CardListItem>
      )}
      <CardListItem
        titleRenderer={cardListItemProps.titleRenderer}
        title="Details"
        icon="panel-stats"
        defaultIsOpen={!analysis.summary}
        collapsible
        cardSectionProps={{ padded: false }}
      >
        <DescriptionList>
          {isForecasted ? (
            <OppDetailsDescriptionFieldsPrediction id={id} opp={opp} />
          ) : (
            <OppDetailsDescriptionFields id={id} opp={opp} />
          )}
        </DescriptionList>
      </CardListItem>
      {!!analysis?.productLineItems?.length && (
        <OppLineItemsTable data={analysis.productLineItems} title="Product Line Items" />
      )}
      {!!analysis?.servicesLineItems?.length && (
        <OppLineItemsTable data={analysis.servicesLineItems} title="Services Line Items" />
      )}
      {!isForecasted && (
        <CardListItem
          title={
            <EntityTitle
              title="Solicitation Notices"
              heading={CardHeadingSmall}
              tags={<Tag minimal>{oppSources?.length || 0}</Tag>}
            />
          }
          icon="inbox-update"
          defaultIsOpen={false}
          collapsible
          className="p-0 space-y-4"
        >
          <OppDetailsSources id={id} />
        </CardListItem>
      )}
    </CardList>
  );
};
