import React from 'react';
import { useAppLayoutContext } from 'app/organisms/AppLayout/AppLayoutContext';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';

export type SearchFilterContainerProps = React.PropsWithChildren;

export const SearchFilterContainer = ({ children }: SearchFilterContainerProps) => {
  const { topNavRef } = useAppLayoutContext();

  return (
    <MainColumn
      tag="aside"
      className={'hidden overflow-y-auto sticky lg:block top-0 py-4 px-px lg:col-span-1'}
      style={{
        height: topNavRef?.current ? `calc(100vh - ${topNavRef.current.clientHeight}px)` : 'calc(100vh - 88px)'
      }}
    >
      {children}
    </MainColumn>
  );
};
