import React from 'react';

import { useGetAwardQuery } from 'api/awardsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { CompoundTag } from '@blueprintjs/core';
import { AwardPageContents } from 'app/organisms/AwardPageContents/AwardPageContents';
import { DrawerType } from 'app/organisms/AppDrawer/constants';

export const showAwardDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  ['/awards/results', '/awards'].includes(window.location.pathname) && drawerType === 'award' && drawerId;

export const AwardDrawerTitle = ({ id }: { id: string }) => {
  const { data: { awardIdPiid, awardType, awardOrIdvFlag, idvType } = {}, isLoading } = useGetAwardQuery({ id });

  if (isLoading) return <Loading type="flex-row" />;

  return <CompoundTag leftContent={awardType || idvType || awardOrIdvFlag}>{awardIdPiid}</CompoundTag>;
};

export const AwardDrawerBody = ({ id }: { id: string }) => {
  return <AwardPageContents id={id} className="max-w-full min-h-vh bg-gray-50 py-12" />;
};
