import React from 'react';

import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { HeaderLoading } from 'app/atoms/HeaderLoading/HeaderLoading';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { OppWorkspacePageContents } from 'app/organisms/OppWorkspacePageContents/OppWorkspacePageContents';
import { useAppDrawerSubNavTab } from 'app/organisms/AppDrawer/useAppDrawerSubNavTab';
import { DrawerType } from 'app/organisms/AppDrawer/constants';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { OppWorkspaceStoreProvider } from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const showWorkspaceDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  ['/opportunities', '/worklist/workspaces', '/worklist/team_workspaces'].includes(window.location.pathname) &&
  drawerType === 'workspaces' &&
  drawerId;

export const OppWorkspaceDrawerTitle = ({ id }: { id: string }) => {
  const { data: workspace, isLoading, isError } = useGetOppWorkspaceQuery({ id });

  if (isError) {
    return <div>Workspace not found</div>;
  }

  return (
    <div>
      {isLoading ? (
        <HeaderLoading />
      ) : (
        <>
          <LinkTag to={`/opportunities/${workspace?.workableId}/workspaces/${id}`} target="_blank">
            {workspace?.name}
          </LinkTag>
          <dd className="text-sm text-gray-500">Workspace</dd>
        </>
      )}
    </div>
  );
};

export const OppWorkspaceDrawerBody = ({ id }: { id: string }) => {
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');
  const tab = useAppDrawerSubNavTab('workspaces');

  return (
    <OppWorkspaceStoreProvider workspaceId={id}>
      <OppWorkspacePageContents id={id} inDrawer tab={oppShowNavEnabled ? tab : undefined} />
    </OppWorkspaceStoreProvider>
  );
};
