import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useRemoveSavedSearchIdParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeSavedSearchIdParam = useCallback(() => {
    searchParams.delete('savedSearchId');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return removeSavedSearchIdParam;
};
